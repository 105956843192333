<div class="color">
    <div class="created-by1" style="margin-right: 0;">
        <mat-icon svgIcon="logo" aria-hidden="false" class="logo_img"></mat-icon>
        <p class="heading" style="margin-right: 0;"></p>
    </div>

    <div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row1" style="margin-right: 0;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row1 block" style="margin-right: 0;">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 welcome"
                    style="margin-bottom: 20px;margin-right: 0px;">
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login">
                    <div class="text-center py-2"><h5>OTP</h5></div>
                    <form (keydown)="keyDownFunction($event)">
                        <div class="field" style="margin-top: 30px;">
                            <mat-form-field class="example-full-width">
                                <mat-label>OTP</mat-label>
                                <input name="otp" id="otp" matInput [(ngModel)]='otpnumber' [formControl]="otpFormControl">
                                <mat-error *ngIf="otpFormControl.hasError('minlength') && !otpFormControl.hasError('required')">
                                    Please enter a valid OTP and must contain 8 characters
                                </mat-error>
                                <mat-error *ngIf="otpFormControl.hasError('required')">
                                    OTP is required
                                </mat-error>
                                <mat-error *ngIf="otpFormControl.hasError('pattern') && !otpFormControl.hasError('required')">
                                    Spaces are not allowed in OTP
                                </mat-error>
                                <mat-hint class="error" *ngIf='errore'>
                                    OTP is incorrect.
                                </mat-hint>
                            </mat-form-field>
                        </div>
                        <div class="row" class="Otptext" *ngIf="errormsg[0]">
                            <div class="col-12 otpbg">An OTP has been sent to {{useremail.email_id}}. </div>
                            <div class="col-12 otpbg1">verify with OTP to login.</div>
                        </div>
                        <div class="Otptext" *ngIf="errormsg[1]">
                            <div class="col-12 otpbg">The OTP you have entered is incorrect. Please Recheck the OTP and Enter or try
                                "Resend OTP"</div>
                        </div>
                        <div *ngIf="errormsg[2]">
                            <h5 class="pass_reset">Password reset Expired! </h5>
                            <p class="expire">You have exhausted the maximum number of attempts. Please wait
                                for 1 hour </p>
                        </div>
                
                        <div class="detail">
                
                            <input class="next_btn" type="submit" class="login-button" value='Next' (click)="submit()">
                            <br />
                            <input style="margin-bottom: 10px;margin-top: 10px; cursor:pointer;" type="submit" class="login-button"
                                value="Resend OTP" (click)="resendotp()"><br />
                            <div>
                                <p class="count">
                                    {{count}} attempts left
                                </p>
                            </div>
                        </div>
                        <div class="failure_msg">
                            <a class="clickhere" (click)='emailpage()'>Click here</a> to go back and change your email id.
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row1 footer"></div>
    </div>
</div>
<div *ngIf="loader" class="overlay">
    <mat-progress-spinner class="spinner" [mode]="mode" [value]="value"> </mat-progress-spinner>
</div>