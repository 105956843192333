<div class="color">
    <div class="created-by1" style="margin-right: 0;">
        <mat-icon svgIcon="logo" aria-hidden="false" class="logo_img"></mat-icon>
        <p class="heading" style="margin-right: 0;"></p>
    </div>

    <div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row1 p-0" style="margin-right: 0;">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row1 block p-0" style="margin-right: 0;">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 welcome"
                    style="margin-bottom: 20px;margin-right: 0px;">
                    Welcome
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login">
                    <form (keydown)="keyDownFunction($event)">
                        <div class="field" style="margin-top: 30px;">
                            <mat-form-field class="example-full-width">
                                <mat-label>Email</mat-label>
                                <input type="email" name="email" id="email" matInput [formControl]="emailFormControl"
                                    [errorStateMatcher]="matcher">

                                <mat-error
                                    *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                    Please enter valid email address.
                                </mat-error>
                                <mat-error *ngIf="emailFormControl.hasError('required')">
                                    Email id required
                                </mat-error>
                                <mat-hint class="error" *ngIf='errore'>
                                    User does not exist. Please enter your registered Email ID
                                </mat-hint>
                            </mat-form-field>
                        </div>

                        <div class="field" style="margin-bottom: 0px;">
                            <mat-form-field class="example-full-width">
                                <input name="password" id="password" [type]="hide ? 'password' : 'text'" matInput
                                    placeholder="Password" [formControl]="passwordFormControl"
                                    [errorStateMatcher]="matcher">
                                <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide"
                                    style="border: none;background-color: transparent;">
                                    <mat-icon style="color:#707B7C  ;height:25px;">
                                        {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <mat-error *ngIf="passwordFormControl.hasError('required')">
                                    Password required
                                </mat-error>
                                <mat-hint class="error" *ngIf='error'>
                                    Password incorrect
                                </mat-hint>

                            </mat-form-field>
                        </div>

                        <div class="link col-12">
                            <a (click)="forgot()"> Forgot password?</a>
                        </div>
                        <div class="detail">
                            <input type="submit" class="login-button" value="Login" (click)="login()" />
                        </div>
                        <div class="detail text-center">
                            <b>-- or --</b>
                        </div>
                        <div class="detail">
                            <input *ngIf="!loginDisplay" type="button" class="login-button" value="SSO Login"
                                (click)="loginPopup()" />
                            <input *ngIf="loginDisplay" type="button" class="login-button" value="SSO Logout"
                                (click)="logout(true)" />
                        </div>
                        <p *ngIf='issue' class="error">{{text}}</p>
                        <div class="smalltext">If you are a new user please enter the temporary password sent to your
                            email.</div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row1 footer"></div>
    </div>
</div>
<div *ngIf="loader" class="overlay">
    <mat-progress-spinner class="spinner" [mode]="mode" [value]="value"> </mat-progress-spinner>
</div>