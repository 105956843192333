import { Component, NgZone, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'cms-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit, OnDestroy {
  otpnumber: any;
  useremail: any;
  count: any = 5;
  errormsg: any = Array(3).fill(false);
  errore: any = false;
  otpFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.pattern(/^(\S+\s*)*(?!\s).*$/)
  ]);
  loader: any = false;
  mode = 'indeterminate';
  value = 50;
  constructor(private router: Router, private apiServices: ApiService, private zone: NgZone) { }

  ngOnInit(): void {
    let data: any = localStorage.getItem('user_data');
    this.useremail = JSON.parse(data);
    this.errormsg[0] = true;
  }
  keyDownFunction($event) {
    this.errore = false;
  }
  emailpage() {
    this.zone.run(() => (this.router.navigate(['/login']))).then();
    localStorage.clear();
  }
  submit() {
    let obj: any = {};
    obj.otp = this.otpnumber;
    this.loader = true;
    if (!this.otpFormControl.hasError('minlength') && !this.otpFormControl.hasError('required') && !this.otpFormControl.hasError('pattern')) {
      if (this.otpnumber !== '') {
        this.apiServices.otpverification(localStorage.getItem('code'), obj).subscribe((result: any) => {
          if (result.status == 1) {
            if (result.token) {
              if (environment.dev) {
                localStorage.setItem('ckey', result.token);
              }
            }
            this.apiServices.details().subscribe((data: any) => {
              if (data.status === 1) {
                localStorage.setItem('userid', data.data.user_id);
                if (data.data.other == '0') {
                  this.router.navigate(['forgot-password/2']);
                } else {
                  localStorage.removeItem('user_data');
                  this.zone.run(() => (this.router.navigate(['/occupancy/dashboard']))).then();
                  let logTime: any = new Date().getTime();
                  localStorage.setItem('loggedTime', logTime);
                }
                this.loader = false;
              }
            });
          } else {
            this.errore = true;
            this.loader = false;
          }
        });
      }
    }
  }

  resendotp() {
    let obj: any = {};
    obj = {email_id: this.useremail.email_id, password: this.useremail.password};
    if (this.count <= 1) {
      this.errormsg = Array(3).fill(false);
      this.errormsg[2] = true;
    } else {
      this.count = this.count - 1;
      this.errormsg = Array(3).fill(false);
      this.errormsg[0] = true;
      this.apiServices.login(obj).subscribe((data: any) => {
        if (data.status === 1) {
          this.otpnumber = null;
        }
      });
    }
  }
  ngOnDestroy(): void {
    localStorage.removeItem('code');
  }
}
