<div class="color">
    <div class="created-by1">
        <mat-icon svgIcon="logo" aria-hidden="false"  class="logo_img"></mat-icon>
        <p class="heading"></p>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row1" style="margin-top: 3%;">
        <div class="col-lg-4 col-md-3 col-sm-12 col-xs-12 row1"> </div>
        <div class="col-lg-4 col-md-3 col-sm-12 col-xs-12 row1 block">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 welcome">
                {{fp}}
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login" *ngIf='this.forgotpasswordflag[0]'
                style="margin-top: 30px;">

                <form (keydown)="keyDownFunction($event)">
                    <div class="field">
                        <mat-form-field class="example-full-width">
                            <mat-label>Email</mat-label>
                            <input type="email" name="email" id="email" matInput [(ngModel)]='useremail'
                                [formControl]="forgotemailFormControl" [errorStateMatcher]="matcher"
                                (keypress)="nospace($event)">

                            <mat-error
                                *ngIf="forgotemailFormControl.hasError('email') && !forgotemailFormControl.hasError('required')">
                                Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="forgotemailFormControl.hasError('required')">
                                Email ID required
                            </mat-error>
                            <mat-hint class="error" *ngIf='message'>
                                User does not exist. Please enter your registered Email ID
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <!-- <div>
                        <p *ngIf='message' class = "errormessage">{{msg2}}</p>
                    </div> -->
                    <div class="detail">
                        <input type="submit" class="login-button" value="Next" (click)="forgotpassword()">
                    </div>
                    <div class="# goback"> Go Back and
                        <a class="loginlink" (click)="login()"> Login.</a>
                    </div>
                </form>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login" *ngIf="this.forgotpasswordflag[1]"
                style="margin-top: 0px;">
                <form (keydown)="keyDownFunction($event)">
                    <div class="field" style="margin-bottom: 15PX;">
                        <mat-form-field class="example-full-width">
                            <mat-label>OTP</mat-label>
                            <input name="otp" id="otp" matInput [(ngModel)]='otpnumber' [formControl]="otpFormControl"
                                [errorStateMatcher]="matcher">
                            <mat-error
                                *ngIf="otpFormControl.hasError('minlength') && !otpFormControl.hasError('required')">
                                Please enter a valid OTP and must contain 8 characters
                            </mat-error>
                            <mat-error *ngIf="otpFormControl.hasError('required')">
                                OTP is required
                            </mat-error>
                            <mat-error
                                *ngIf="otpFormControl.hasError('pattern') && !otpFormControl.hasError('required')">
                                Spaces are not allowed in OTP
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row" class="Otptext" *ngIf="this.messageflag[0]">
                        <div class="col-12 otpbg">An OTP has been sent to {{useremail}}. </div>
                        <div class="col-12 otpbg1">verify with OTP to change your password</div>
                    </div>
                    <div class="Otptext" *ngIf="this.messageflag[1]">
                        <div class="col-12 otpbg">The OTP you have entered is incorrect. Please Recheck the OTP and Enter or try "Resend OTP"</div>
                    </div>
                    <div *ngIf="this.forgotpasswordflag[5]">
                        <h5 class="pass_reset">Password reset Expired! </h5>
                        <p class="expire">You have exhausted the maximum number of attempts. Please wait
                            for 1 hour </p>
                    </div>

                    <div class="detail" *ngIf="this.messageflag[2]">

                        <input class="next_btn" type="submit" class="login-button" value='Next' (click)="submit()">
                        <br />
                        <input 
                        style="margin-bottom: 10px;margin-top: 10px; cursor:pointer;"
                            type="submit" class="login-button" value="Resend OTP" (click)="resendotp()"><br />
                        <div>
                            <p class="count">
                                {{count}} attempts left
                            </p>
                            <!-- <p *ngIf='message' class="error">{{msg1}}</p> -->
                        </div>
                    </div>
                    <div class="failure_msg">
                        <a class="clickhere" (click)='emailpage()'>Click here</a> to go back and change your email id.
                    </div>
                </form>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login" *ngIf='this.forgotpasswordflag[2]'
                style="margin-top: 30px;">

                <form (keydown)="keyDownFunction($event)">
                    <div class="field">
                        <mat-form-field class="example-full-width">
                            <mat-label> New password</mat-label>

                            <input name="password" id="passwd" [type]="hide ? 'password' : 'text'" matInput
                                [formControl]="resetpwdFormControl1" [errorStateMatcher]="matcher"
                                [(ngModel)]="newpassword">
                            <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" class="hideshow">
                                <mat-icon class="eyeicon">
                                    {{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>

                            <mat-error *ngIf="!resetpwdFormControl1.hasError('required') && resetpwdFormControl1.hasError('pattern')">
                                <p>Password must be atleast eight characters(1 Uppercase, lowercase, numeric, special character).
                                </p>
                            </mat-error>
                            <mat-error *ngIf="resetpwdFormControl1.hasError('required')">
                                <p>New password required</p>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="conf_pass">
                        <mat-form-field class="example-full-width" style="padding-top: 10px;">
                            <mat-label>Confirm new password</mat-label>
                            <input name="password" id="conpassword" [type]="hide1 ? 'password' : 'text'" matInput
                                [formControl]="resetpwdFormControl" [errorStateMatcher]="matcher"
                                [(ngModel)]="confirmpassword">
                            <button type="button" mat-icon-button matSuffix (click)="hide1 = !hide1"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1" class="hideshow">
                                <mat-icon class="eyeicon">
                                    {{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-error
                                *ngIf="!resetpwdFormControl.hasError('required') && resetpwdFormControl.hasError('pattern')">
                                <p>Password must be atleast eight characters(1 Uppercase, lowercase, numeric, special character).</p>
                            </mat-error>
                            <mat-error *ngIf="resetpwdFormControl.hasError('required')">
                                <p>Confirm password is required</p>
                            </mat-error>
                            <!-- <span id='message'></span> -->
                            <mat-hint class="error" *ngIf='hint'>
                                <p>New password does not match with Confirm password</p>
                            </mat-hint>
                            <mat-hint class="error" *ngIf='hint2'>
                                <p>OTP password and New password must be different</p>
                            </mat-hint>
                        </mat-form-field>
                    </div>
                    <p style="text-align: center;padding: 10px;margin-top: 5px;">New password shouldn't be similar to
                        the previous password, password must be atleast eight characters(1 Uppercase, lowercase, numeric, special character).</p>

                    <p class="error">{{msg}}</p>
                    <div class="detail" style="margin-bottom: 30px;margin-top: 20px;">
                        <input type="submit" class="login-button1" value="Change Password" id="submit"
                            (click)="conformpassword()">
                    </div>
                    <div class="failure_msg">
                        Click here to go back to<a class="clickhere" (click)='login()'>Login</a> page.
                    </div>
                </form>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login" *ngIf="this.forgotpasswordflag[3]">
                <form>
                    <h5 class="pass_reset">Congratulations! </h5>
                    <p class="expire">Your password has been changed. You may now login with your new password. To Log
                        in click on the 'Login' button</p>
                    <input style="margin-bottom: 30px;text-align: center;" class="login-button" value='Login'
                        (click)="backtologin()">
                </form>
            </div>

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 login" *ngIf="this.forgotpasswordflag[4]">
                <h5 class="pass_reset">Password reset Expired! </h5>
                <p class="expire">You have exhausted the maximum number of attempts. Please wait
                    for 24 hour </p>
                <input style="margin-bottom: 30px;" class="login-button" value='Login' (click)="backtologin()">
            </div>
            <div class="col-lg-4 col-md-3 col-sm-12 col-xs-12 row1"></div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 row1 footer"></div>
    </div>
</div>