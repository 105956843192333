// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://engage.cmselectra.com/admin',
  ssoUrl: 'https://login.microsoftonline.com/common',
  ssoUser: 'https://graph.microsoft.com/v1.0/me',
  ssoCalendar: 'https://graph.microsoft.com/v1.0/me/calendars',
  // apiUrl: 'http://192.168.100.23:8787/admin',
  // apiUrl: 'http://localhost:8080/admin',
  // apiUrl: 'https://9003-183-82-120-226.in.ngrok.io/admin',
  // apiUrl: 'https://0baf-183-82-120-226.in.ngrok.io/admin',
  firebase: {
    apiKey: 'AIzaSyCOcuxaZU308dEr-DZITUrCD4ZsG2ZBxnY',
    authDomain: 'occpancydev.firebaseapp.com',
    projectId: 'occpancydev',
    storageBucket: 'occpancydev.appspot.com',
    messagingSenderId: '951697054207',
    appId: '1:951697054207:web:d3c8f908a9df340e7cefca'
  },
  dev: true,
  // imagesite: 'https://cms-stage-images.b1automation.com',
  // imagesite: 'https://d2ibuwi329suci.cloudfront.net',
  imagesite: 'https://d2ibuwi329suci.cloudfront.net',
  wss: 'os-mqtt.b1automation.com',
  port: 8080,
  user: 'testing',
  password: 'testing',
  oldUrl: 'https://engage.cmselectra.com',
  newUrl: 'https://engage.cmselectra.com/v1',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
