import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Paho } from 'ng2-mqtt/mqttws31';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
export const WS_ENDPOINT = environment.wss;

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  client: any;
  obm: any = [false];
  obe: any = [false];
  connected: any = false;
  internalstimer: any;
  constructor(private api: ApiService) {
  }
  getInitConnect() {
    if (!this.connected) {
      this.client = new Paho.MQTT.Client(environment.wss, Number(environment.port), 'app_server_data_engage' + (Math.random() * 100).toString(16).substring(2, 8));
      const options = {
        useSSL: true,
        timeout: 10,
        userName: environment.user,
        password: environment.password,
        keepAliveInterval: 10,
        cleanSession: true,
        onSuccess: this.onConnect.bind(this),
        onFailure: this.doFail.bind(this),
      };
      this.client.onConnectionLost = this.onConnectionLost.bind(this);
      this.client.onMessageArrived = this.onMessageArrived.bind(this);
      this.client.connect(options);
    }
  }
  onConnect() {
    this.connected = true;
    console.log('Connected true');

  }
  doFail(e: any) {
    console.log('Failed:' + JSON.stringify(e));
  }
  onMessageArrived(message: any) {
    if ((message.destinationName).indexOf('web/devices/') > -1) {
      if ((message.destinationName).indexOf('/event') > -1) {
        const [state, observer] = this.obm;
        if (state) {
          observer.next(message.payloadString);
        }
      }
      if ((message.destinationName).indexOf('/notification') > -1) {
        const [state, observer] = this.obe;
        if (state) {
          observer.next(message.payloadString);
        }

      }
    }

  }
  onConnectionLost(responseObject: any) {
    if (responseObject.errorCode !== 0) {
      console.log('onConnectionLost:' + responseObject.errorMessage);
    } else {
      this.connected = false;
      this.getInitConnect();
    }
  }
  public getMessages = (topic: any) => {
    try {
      setTimeout(() => {
        this.api.topic().subscribe((result: any) => {
          if (result.status == 1) {
            this.client.subscribe(`web/devices/${result.data}/event`, {});
          }
        });
      }, 5000);
    } catch (err) {
      console.log(err.message);
    }
    return new Observable((observer) => {
      this.obm = [true, observer];
    });
  }
  public getEvents = (topic) => {
    try {
      setTimeout(() => {
        this.api.topic().subscribe((result: any) => {
          if (result.status == 1) {
            this.client.subscribe(`web/devices/${result.data}/notification`, {});
          }
        });
      }, 5000);
    } catch (err) {
      console.log(err.message);
    }
    return new Observable((observer) => {
      this.obe = [true, observer];
    });

  }
  setUnSubscription(topic: any, state: any) {
    try {
      this.api.topic().subscribe((result: any) => {
        if (result.status == 1) {
          if (state && topic.length > 20) {
            this.client.unsubscribe(`web/devices/${result.data}/event`, {});
          }
          if (!state && topic.length > 20) {
            this.client.unsubscribe(`web/devices/${result.data}/notification`, {});
          }
        }
      });
      this.client.disconnect();
    } catch (err) {

    }
  }
  mqttDisconnect() {
    try {
      if (this.client && this.client.isConnected()) {
        this.client.disconnect();
      }
      // this.connected = false;
    }
    catch (err) {

    }
  }
}
