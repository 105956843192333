<div class="dialog">
    <div class="col-12 heading text-center" mat-dialog-title>
        <p>{{data.header}}</p>
    </div>
    <div class="row line"></div>
    <div class="message">
        {{message}}
    </div>
    <div class="row line"></div>
    <div class="row submit">
        <div class="col-12 text-center" (click) = "closeMe()">
          <button class="cancel" >{{loginname}}</button>
        </div>
    </div>
</div>