import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MyErrorStateMatcher } from '../../shared/error-state-matcher';

@Component({
  selector: 'cms-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
  forgotemailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
    // Validators.pattern('^([a-zA-z0-9.\.]+)@([a-zA-z0-9.]+)(([a-z]+\.)+[a-z]{2,})$'),
  ]);
  otpFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.pattern(/^(\S+\s*)*(?!\s).*$/)
  ]);
  resetpwdFormControl1 = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
    Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^-_])[A-Za-z\d@$!%*?&#^-_]{8,}$/),
  ]);
  resetpwdFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(8),
    Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^-_])[A-Za-z\d@$!%*?&#^-_]{8,}$/),
  ]);
  matcher = new MyErrorStateMatcher();
  hide = true;
  hide1 = true;
  pwd = false;
  code: any;
  otpcode: any = null;
  codeotp: any;
  chpwdcode: any = null;
  useremail;
  otpn;
  forgotpass = true;
  otp = false;
  successOtp = false;
  resetpassword = false;
  isFailure;
  isFailure1;
  newpassword;
  confirmpassword;
  count = 5;
  otpnumber;
  msg = '';
  hint = false;
  hint2 = false;
  msg1;
  msg2 = '';
  message;
  fp = 'Forgot Password';
  otpText = '';
  interval;
  min;
  sec;
  minutes;
  seconds: number;
  countDown: any = null;
  expire = false;
  forgotpasswordflag: any = Array(10).fill(false);
  messageflag: any = Array(3).fill(false);
  otppassword;
  type;
  constructor(private activeroute: ActivatedRoute, private zone: NgZone, private router: Router, private apiServices: ApiService) { }

  ngOnInit(): void {
    this.type = this.activeroute.snapshot.paramMap.get('type');
    if (this.type === '2') {
      this.forgotpasswordflag[2] = true;
      this.fp = 'Change Password';
      // this.getaccess();
    } else {
      this.fp = 'Forgot Password';
      this.forgotpasswordflag[0] = true;
      this.getaccess();
    }
  }
  getaccess() {
    let resp = null;
    this.apiServices.details().subscribe((data: any) => {
      resp = data;
    }, err => {
      console.log(err);
    }, () => {
      if (resp.status === 1) {
        if (resp.data.other === '0') {
          this.forgotpasswordflag = Array(10).fill(false);
          this.router.navigate(['forgot-password/2']);
          // let data: any = localStorage.getItem('user_data');
          // let userData: any = JSON.parse(data);
          // this.otppassword = userData.password;
          this.forgotpasswordflag[2] = true;
          this.fp = 'Change Password';
        } else {
          this.zone.run(() => (this.router.navigate(['/occupancy/dashboard']))).then();
        }
      }
    });
  }
  login() {
    this.apiServices.clearstorage();
    this.router.navigate(['/login']);
  }
  emailpage() {
    this.count = 5;
    this.code = null;
    this.otpFormControl.clearValidators();
    this.messageflag = Array(3).fill(false);
    this.forgotpasswordflag = Array(10).fill(false);
    this.forgotpasswordflag[0] = true;
    this.otpnumber = undefined;

  }
  forgotpassword() {
    this.forgotpasswordflag = Array(10).fill(false);
    this.forgotpasswordflag[0] = true;
    let obj: any = {};

    this.useremail = (document.getElementById('email') as HTMLInputElement).value;
    obj.email_id = this.useremail;
    if (!this.forgotemailFormControl.hasError('required') && !this.forgotemailFormControl.hasError('email')) {
      if (this.useremail !== null && this.useremail !== '' && this.useremail !== undefined) {

        this.apiServices.forgotpassword(obj).subscribe((data: any) => {
          if (data.status === 1) {
            this.code = data.code;
            this.forgotpasswordflag[0] = false;
            this.forgotpasswordflag[1] = true;
            this.messageflag[0] = true;
            this.messageflag[2] = true;
            this.otpnumber = undefined;
          } else {
            this.message = true;
          }
        });
      }
    }

  }

  submit() {
    let obj: any = {};
    obj.otp = this.otpnumber;
    if (!this.otpFormControl.hasError('minlength') && !this.otpFormControl.hasError('required') && !this.otpFormControl.hasError('pattern')) {
      if (this.otpnumber !== '') {
        this.apiServices.updatepassword(this.code, obj).subscribe((data: any) => {
          if (data.status === 1) {
            this.messageflag[0] = true;
            this.forgotpasswordflag[1] = false;
            this.forgotpasswordflag[2] = true;
          } else if (data.status === 0) {
            this.messageflag[0] = false;
            this.messageflag[1] = true;
          }
        });
      }

    }
  }

  resendotp() {
    let obj: any = {};
    obj.email_id = this.useremail;
    if (this.count <= 1) {
      this.forgotpasswordflag = Array(10).fill(false);
      this.forgotpasswordflag[4] = true;
    } else {
      this.count = this.count - 1;
      this.messageflag[0] = true;
      this.messageflag[1] = false;
      this.apiServices.forgotpassword(obj).subscribe((data: any) => {
        if (data.status === 1) {
          this.otpnumber = null;
        }
      });
    }

  }

  conformpassword() {
    this.hint = false;
    this.hint2 = false;
    this.newpassword = (document.getElementById('passwd') as HTMLInputElement).value;
    this.confirmpassword = (document.getElementById('conpassword') as HTMLInputElement).value;
    const obj: any = { newpassword: this.newpassword };
    if (!this.resetpwdFormControl1.hasError('required') && !this.resetpwdFormControl.hasError('required')
      && !this.resetpwdFormControl1.hasError('minLength') && !this.resetpwdFormControl.hasError('minLength')
      && !this.resetpwdFormControl1.hasError('pattern') && !this.resetpwdFormControl.hasError('pattern')) {
      if (this.newpassword === this.confirmpassword) {
        if (this.type !== '2') {
          this.apiServices.updatepassword(this.code, obj).subscribe((data: any) => {
            if (data.status === 1) {
              this.forgotpasswordflag[1] = false;
              this.forgotpasswordflag[2] = false;
              this.forgotpasswordflag[3] = true;
              localStorage.clear();
            }
          });
        } else {
          let data: any = localStorage.getItem('user_data');
          let userData: any = JSON.parse(data);
          this.otppassword = userData.password;
          if (this.newpassword === this.otppassword) {
            this.hint2 = true;
            this.hint = false;
          } else {
            const obj: any = { password: this.otppassword, npassword: this.newpassword };
            this.apiServices.updatedata(obj).subscribe((data: any) => {
              if (data.status === 1) {
                this.forgotpasswordflag[1] = false;
                this.forgotpasswordflag[2] = false;
                this.forgotpasswordflag[3] = true;
                localStorage.clear();
              }
            });
          }
        }
      } else {
        this.hint = true;
        this.hint2 = false;
      }
    }
  }
  backtologin() {
    localStorage.clear();
    this.router.navigate(['login']);
  }
  keyDownFunction(event: any) {
    this.hint2 = false;
    this.hint = false;
    this.messageflag[1] = false;
    this.message = false;
  }
  nospace(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode === 32) {
      return false;
    }
    return true;
  }
}
