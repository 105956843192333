import { Component, OnInit, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ApiService, EventsService } from '../../services';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { MyErrorStateMatcher } from '../../shared/error-state-matcher';
import { environment } from 'src/environments/environment';
import { SessionDialogeComponent } from '../session-dialoge/session-dialoge.component';
import { MatDialog } from '@angular/material/dialog';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'cms-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  email;
  loader = false;
  password;
  error = false;
  errore = false;
  errmsg;
  loginpassword = true;
  forgotpass = false;
  issue = false;
  text: any = '';
  mode = 'indeterminate';
  value = 50;
  isIframe = false;
  loginDisplay = false;
  private readonly destroying = new Subject<void>();

  constructor(private apiService: ApiService, private zone: NgZone, private router: Router, public dialog: MatDialog, @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private authService: MsalService, private msalBroadcastService: MsalBroadcastService,private event:EventsService) { }
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
    // Validators.pattern('^([a-zA-z0-9.]+)@([a-zA-z0-9.]+)(([a-z]+\.)+[a-z]{2,})$'),
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
  ]);
  matcher = new MyErrorStateMatcher();
  hide = true;
  webToken: any = null;
  ngOnInit(): void {
  }
  ngAfterViewInit() {
    let loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    let logout = localStorage.getItem('logoutsso');
    if (!loginDisplay && logout != undefined && logout == 'true') {
      this.apiService.logout().subscribe(() => {
        this.apiService.clearstorage();
        localStorage.setItem('sso_dialog', '1');
      });
    } else {
      // this.apiService.details().subscribe((resp: any) => { });
      this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
      this.setLoginDisplay();

      this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        )
        .subscribe((result: EventMessage) => {
          if (this.authService.instance.getAllAccounts().length === 0) {
            window.location.pathname = '/';
          } else {
            this.setLoginDisplay();
          }
        });

      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this.destroying)
        )
        .subscribe(() => {
          this.setLoginDisplay();
          this.checkAndSetActiveAccount();
        });
    }
  }
  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  keyDownFunction(event) {
    this.errore = false;
    this.error = false;
  }
  login() {
    localStorage.removeItem('sso_dialog');
    this.loader = true;
    this.email = (document.getElementById('email') as HTMLInputElement).value;
    this.password = (document.getElementById('password') as HTMLInputElement).value;
    let resdata;
    if (!this.emailFormControl.hasError('required') && !this.emailFormControl.hasError('email') && !this.passwordFormControl.hasError('required')) {
      this.apiService.login({ email_id: this.email, password: this.password }).subscribe((data: HttpResponse<any>) => {
        resdata = data;
        if (resdata.status === 0) {
          this.loader = false;
          this.text = resdata.message;
          if (this.text === 'User does not exist. Please enter your registered Email ID') {
            this.errore = true;
            this.error = false;
          } else if (this.text === 'Password incorrect') {
            this.error = true;
            this.errore = false;
          } else {
            let dialogRef: any;
            this.loader = false;
            dialogRef = this.dialog.open(SessionDialogeComponent, {
              data: {
                message: resdata.message,
                type: 'expired',
                header: 'Session Expired !!!',
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result.data !== undefined) {
                this.apiService.clearstorage();
                this.router.navigate(['/login']);
              }
            });
          }
        } else if (resdata.status === 1) {
          let userData: any = {email_id: this.email, password: this.password};
          localStorage.setItem('user_data', JSON.stringify(userData));
          localStorage.setItem('code', resdata.code);
          this.zone.run(() => (this.router.navigate(['/otp']))).then();
        }
      });
    } else {
      this.loader = false;
    }
  }
  forgot() {
    this.router.navigate(['forgot-password/1']);
  }

  loginPopup() {
    sessionStorage.clear();
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          localStorage.setItem('ssotoken', `${response.tokenType} ${response.accessToken}`);
          this.authService.instance.setActiveAccount(response.account);
          this.getProfile(`${response.tokenType} ${response.accessToken}`);
        });
    } else {
      this.authService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          localStorage.setItem('ssotoken', `${response.tokenType} ${response.accessToken}`);
          this.authService.instance.setActiveAccount(response.account);
          this.getProfile(`${response.tokenType} ${response.accessToken}`);
        });
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/'
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this.destroying.next(undefined);
    this.destroying.complete();
    this.event.mqttDisconnect()
  }

  getProfile(token) {
    localStorage.removeItem('sso_dialog');
    this.apiService.http.get(environment.ssoUser, { headers: { Authorization: token } })
      .subscribe((profile: any) => {
        this.setLoginDisplay();
        this.loader = true;
        let resdata;
        this.apiService.login({ email_id: profile.userPrincipalName, sso_id: profile.id }).subscribe((data: HttpResponse<any>) => {
          resdata = data;
          if (resdata.status === 0) {
            this.loader = false;
            this.text = resdata.message;
            if (this.text === 'User does not exist. Please enter your registered Email ID') {
              this.errore = true;
              this.error = false;
            }
          } else if (resdata.status === 1) {
            let userData: any = { email_id: profile.userPrincipalName, sso_id: profile.id };
            localStorage.setItem('code', resdata.code);
            localStorage.setItem('user_data', JSON.stringify(userData));
            this.zone.run(() => (this.router.navigate(['/otp']))).then();
          }
        });
      });
  }
}
