import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { SessionDialogeComponent } from '../admin/session-dialoge/session-dialoge.component';

const BaseUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  sessionExpired = true;
  constructor(public http: HttpClient, private router: Router, public dialog: MatDialog) { }

  ResponseInfo: any = ((result: any) => {
    let ssosessoin = localStorage.getItem('sso_dialog');
    if (result.status == 5) {
      if ((this.router.url).indexOf('/login') === -1) {
        let dialogRef;
        if (this.sessionExpired && ssosessoin == undefined) {
          this.sessionExpired = false;
          dialogRef = this.dialog.open(SessionDialogeComponent, {
            width: '550px',
            data: {
              message: result.message || result.massage,
              type: 'session',
              header: 'Session Expired !!!',
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            this.dialog.closeAll();
            if (result.data !== undefined) {
              this.clearstorage();
              this.router.navigate(['/login']);
            }
          });
        }
      }
      return result;
    } else {
      if ((this.router.url).indexOf('login') > -1) {
        this.router.navigate(['/occupancy/dashboard']);
      }
      return result;
    }
  });
  login(data) {
    return this.http.post(BaseUrl + '/users/login', data);
  }
  otpverification(code, data) {
    return this.http.post(BaseUrl + `/${code}/verification`, data).pipe(map(this.ResponseInfo));
  }
  forgotpassword(obj) {
    return this.http.post(BaseUrl + '/web/user/forgot', obj).pipe(map(this.ResponseInfo));
  }
  updatepassword(code, obj) {
    return this.http.post(`${BaseUrl}/web/user/update/${code}`, obj).pipe(map(this.ResponseInfo));
  }
  details() {
    return this.http.get(BaseUrl + '/user/details').pipe(map(this.ResponseInfo));
  }
  topic() {
    return this.http.get(BaseUrl + '/user/topic').pipe(map(this.ResponseInfo));
  }
  getLockerDetails() {
    return this.http.get(BaseUrl + '/locker/type').pipe(map(this.ResponseInfo));
  }
  getLockerTemplate(id: any) {
    return this.http.get(BaseUrl + `/locker/${id}/template`).pipe(map(this.ResponseInfo));
  }
  getLockerType(locid: any, floorid: any, type: any) {
    return this.http.get(BaseUrl + `/locker/${locid}/${floorid}/locker/${type}`).pipe(map(this.ResponseInfo));
  }
  getLockerTypeDetails(locid: any, floorid: any, type: any) {
    return this.http.get(BaseUrl + `/locker/${locid}/${floorid}/lockerdetails/${type}`).pipe(map(this.ResponseInfo));
  }
  getHostDetails(locid: any, lockerid: any, type: any) {
    return this.http.get(BaseUrl + `/locker/${locid}/locker/${lockerid}/${type}`).pipe(map(this.ResponseInfo));
  }
  getLockerInfo(locid: any, lockerid: any, obj: any) {
    return this.http.put(BaseUrl + `/locker/${locid}/locker/${lockerid}`, obj).pipe(map(this.ResponseInfo));
  }
  setLockerDetails(locid: any, floorId: any, lockerid: any, type: any, idType: any, obj: any) {
    return this.http.post(BaseUrl + `/locker/${locid}/${floorId}/locker/${lockerid}/${type}/${idType}`, obj).pipe(map(this.ResponseInfo));
  }
  updateLockerDetails(locid: any, floorId: any, lockerid: any, obj: any) {
    return this.http.post(BaseUrl + `/locker/${locid}/${floorId}/locker/${lockerid}/update`, obj).pipe(map(this.ResponseInfo));
  }
  deleteLocker(locid: any, floorId: any, type: any, obj: any, secret: any) {
    let headerobj: any = {};
    headerobj.secret = secret;
    return this.http.post(BaseUrl + `/locker/${locid}/${floorId}/${type}/delete`, obj, { headers: headerobj }).pipe(map(this.ResponseInfo));
  }
  addLockersInfo(locid: any, uuid: any, lockerdetailsid: any, lockerid: any, obj: any) {
    return this.http.post(BaseUrl + `/locker/${locid}/locker/${uuid}/${lockerdetailsid}/${lockerid}`, obj).pipe(map(this.ResponseInfo));
  }
  updateLocker(locid: any, lockerid: any, obj: any) {
    return this.http.put(BaseUrl + `/locker/${locid}/locker/${lockerid}`, obj).pipe(map(this.ResponseInfo));
  }
  logoConfiguration() {
    return this.http.get(BaseUrl + '/user/configuration').pipe(map(this.ResponseInfo));
  }
  uploadBookingUsers(userType: any, obj) {
    return this.http.post(BaseUrl + `/user/${userType}/update`, obj).pipe(map(this.ResponseInfo));
  }
  kioskdetails() {
    return this.http.get(BaseUrl + '/user/kiosksessions').pipe(map(this.ResponseInfo));
  }
  kiosklogout(id: any, secret: any) {
    let headerobj: any = {};
    headerobj.secret = secret;
    return this.http.delete(BaseUrl + `/user/kiosksessions/${id}`, { headers: headerobj }).pipe(map(this.ResponseInfo));
  }
  kioskupdate(id: any, secret: any, obj: any) {
    let headerobj: any = {};
    headerobj.secret = secret;
    return this.http.put(BaseUrl + `/user/kiosksessions/${id}`, obj, { headers: headerobj }).pipe(map(this.ResponseInfo));
  }
  meetingpaneldetails() {
    return this.http.get(BaseUrl + '/user/meetingsessions').pipe(map(this.ResponseInfo));
  }
  meetingpanellogout(id: any, secret: any) {
    let headerobj: any = {};
    headerobj.secret = secret;
    return this.http.delete(BaseUrl + `/user/meetingsessions/${id}`, { headers: headerobj }).pipe(map(this.ResponseInfo));
  }
  // kioskupdate(id: any, secret: any, obj: any) {
  //   let headerobj: any = {};
  //   headerobj.secret = secret;
  //   return this.http.put(BaseUrl + `/user/kiosksessions/${id}`, obj, { headers: headerobj }).pipe(map(this.ResponseInfo));
  // }
  visitordetails() {
    return this.http.post(BaseUrl + '/user/visitor', {}).pipe(map(this.ResponseInfo));
  }
  getvisitorhistory(type: any, obj: any) {
    return this.http.post(`${BaseUrl}/user/${type}/visitor`, obj).pipe(map(this.ResponseInfo));
  }
  check() {
    return this.http.get(BaseUrl + '/user/check').pipe(map(this.ResponseInfo));
  }
  updatedata(obj) {
    return this.http.put(BaseUrl + '/user/update', obj).pipe(map(this.ResponseInfo));
  }
  getlocation() {
    return this.http.get(BaseUrl + '/location/super/menu').pipe(map(this.ResponseInfo));
  }
  getRgbStates() {
    return this.http.get(BaseUrl + '/location/rgbconfig').pipe(map(this.ResponseInfo));
  }
  getAll(locid) {
    return this.http.get(`${BaseUrl}/location/super/${locid}/all`).pipe(map(this.ResponseInfo));
  }
  AddUser(Obj) {
    return this.http.post(BaseUrl + '/user/add', Obj).pipe(map(this.ResponseInfo));
  }
  deleteUser(userid, secret) {
    let headerobj: any = {};
    headerobj.secret = secret;
    return this.http.delete(`${BaseUrl}/user/delete/${userid}`, { headers: headerobj }).pipe(map(this.ResponseInfo));
  }
  updateUser(userid, data) {
    return this.http.post(`${BaseUrl}/user/update/${userid}`, data).pipe(map(this.ResponseInfo));
  }
  getUserDetailsByUserId(userid) {
    return this.http.get(`${BaseUrl}/map/${userid}/get`).pipe(map(this.ResponseInfo));
  }

  getUsersByLocationId(usertype, locid) {
    return this.http.get(`${BaseUrl}/user/${usertype}/get/${locid}`).pipe(map(this.ResponseInfo));
  }
  assignteammembers(obj, assigntype, secret) {
    let headerobj: any = {};
    headerobj.secret = secret;
    return this.http.post(`${BaseUrl}/user/assignteammembers?type=${assigntype}`, obj, { headers: headerobj }).pipe(map(this.ResponseInfo));
  }
  getAssignedUsers(locid, managerid, assigntype) {
    return this.http.post(`${BaseUrl}/user/${locid}/getteamusers?type=${assigntype}&manager_id=${managerid}`, {}).pipe(map(this.ResponseInfo));
  }

  addUserWorkspace(userId, obj) {
    return this.http.post(`${BaseUrl}/map/${userId}/add`, obj).pipe(map(this.ResponseInfo));
  }

  updateUserWorkspace(userid, uuid, data) {
    return this.http.put(`${BaseUrl}/map/${userid}/update/${uuid}`, data).pipe(map(this.ResponseInfo));
  }

  deleteUserWorkspace(userid, uuid) {
    return this.http.delete(`${BaseUrl}/map/${userid}/delete/${uuid}`).pipe(map(this.ResponseInfo));
  }

  getAllLocationDesk(floorId) {
    return this.http.get(`${BaseUrl}/Desk/getdeskloc/${floorId}`).pipe(map(this.ResponseInfo));
  }

  getdeskinformation(deskid) {
    return this.http.get(`${BaseUrl}/desk/getdeskinfo/${deskid}`).pipe(map(this.ResponseInfo));
  }
  setdeskinformation(deskid, obj) {
    return this.http.post(`${BaseUrl}/desk/setdeskinfo/${deskid}`, obj).pipe(map(this.ResponseInfo));
  }
  getSync(secret) {
    let headerobj: any = {};
    headerobj.secret = secret;
    return this.http.get(`${BaseUrl}/sso/get`, { headers: headerobj }).pipe(map(this.ResponseInfo));
  }
  getSCIMSync(secret) {
    let headerobj: any = {};
    headerobj.secret = secret;
    return this.http.post(`${BaseUrl}/sso/scim/get`, {}, { headers: headerobj }).pipe(map(this.ResponseInfo));
  }
  generateSCIMSync(secret) {
    let headerobj: any = {};
    headerobj.secret = secret;
    return this.http.put(`${BaseUrl}/sso/scim/generate`, {}, { headers: headerobj }).pipe(map(this.ResponseInfo));
  }
  enablesso() {
    return this.http.get(`${BaseUrl}/sso/sso`).pipe(map(this.ResponseInfo));
  }
  addSyncUsers(obj, secret) {
    let headerobj: any = {};
    headerobj.secret = secret;
    return this.http.post(`${BaseUrl}/sso/add`, obj, { headers: headerobj }).pipe(map(this.ResponseInfo));
  }
  addSyncUserCheck(obj) {
    return this.http.post(`${BaseUrl}/sso/userscheck`, obj).pipe(map(this.ResponseInfo));
  }
  updateSyncUsers(path, obj) {
    return this.http.put(`${BaseUrl}/${path}`, obj).pipe(map(this.ResponseInfo));
  }
  assignRooms(roomid, obj, secret) {
    let headerobj: any = {};
    headerobj.secret = secret;
    return this.http.post(`${BaseUrl}/location/room/${roomid}/assign`, obj, { headers: headerobj }).pipe(map(this.ResponseInfo));
  }
  unassignRooms(uuid, secret) {
    let headerobj: any = {};
    headerobj.secret = secret;
    return this.http.delete(`${BaseUrl}/location/room/${uuid}/assign`, { headers: headerobj }).pipe(map(this.ResponseInfo));
  }
  getassignRooms(locid) {
    return this.http.get(`${BaseUrl}/location/room/${locid}/assign`).pipe(map(this.ResponseInfo));
  }
  checkPassword(secret) {
    let headerobj: any = {};
    headerobj.secret = secret;
    return this.http.post(`${BaseUrl}/sso/users/check`, {}, { headers: headerobj }).pipe(map(this.ResponseInfo));
  }
  addUsers(obj, secret) {
    // debugger;
    let headerobj: any = {};
    headerobj.secret = secret;
    return this.http.post(`${BaseUrl}/sso/users`, obj, { headers: headerobj }).pipe(map(this.ResponseInfo));
  }
  syncAuthourize(type: any, group: any) {
    return this.http.get(`${BaseUrl}/sso/${type}/authorize?${group}`).pipe(map(this.ResponseInfo));
  }
  getAllRoomDesk(roomId) {
    return this.http.get(`${BaseUrl}/Desk/getdesk/${roomId}`).pipe(map(this.ResponseInfo));
  }
  getcleaningHistoryDetails(obj) {
    return this.http.post(`${BaseUrl}/desk/states`, obj).pipe(map(this.ResponseInfo));
  }
  addcoordinatesDesk(data, did) {
    return this.http.put(`${BaseUrl}/desk/update/${did}`, data).pipe(map(this.ResponseInfo));
  }

  /* get the notifications scene settings api */
  getLocationDetails(locid) {
    return this.http.get(`${BaseUrl}/location/super/${locid}/get`).pipe(map(this.ResponseInfo));
  }

  /* update notification scene settings api */
  updateLocationDetails(locid, data) {
    return this.http.put(`${BaseUrl}/location/super/${locid}/update`, data).pipe(map(this.ResponseInfo));
  }

  /* get the details of the selected smartbox or occ sensor */
  getStates(type, id, obj) {
    if (type === '0') {
      return this.http.get(`${BaseUrl}/event/${id}/state/?count=100`).pipe(map(this.ResponseInfo));
    } else if (type === '1') {
      return this.http.get(`${BaseUrl}/event/${id}/state`).pipe(map(this.ResponseInfo));
    } else if (type === '2') {
      return this.http.get(`${BaseUrl}/event/${id}/state/?count=100&start=${obj.start}`).pipe(map(this.ResponseInfo));
    }
  }
  /* set the height, min, max values for occ and sb */
  setDeviceEnergyandHeight(deviceid, obj) {
    return this.http.post(`${BaseUrl}/event/${deviceid}/state`, obj).pipe(map(this.ResponseInfo));
  }

  /* get the room details of floor */
  getFloorRoomDetails(floorid) {
    return this.http.get(`${BaseUrl}/location/room/${floorid}/get`).pipe(map(this.ResponseInfo));
  }

  /* get the room devices details occ/all/box */
  getRoomDevices(roomid, key) {
    return this.http.get(`${BaseUrl}/device/room/${roomid}/gettype/${key}`).pipe(map(this.ResponseInfo));
  }

  /* get all the box/occ of particular location */
  getLocationDevices(locid, type) {
    return this.http.get(`${BaseUrl}/device/location/${locid}/gettype/${type}`).pipe(map(this.ResponseInfo));
  }
  getOccStatus(locid, locType, type, quetparams: any = '') {
    return this.http.get(`${BaseUrl}/device/${locType}/${locid}/gettype/${type}${quetparams}`).pipe(map(this.ResponseInfo));
  }
  getDeviceType(locid: any, type: any, dtype: any) {
    return this.http.get(`${BaseUrl}/device/${locid}/type/${type}/${dtype}`).pipe(map(this.ResponseInfo));
  }

  /* get all the box/occ of particular floor */
  getFloorDevices(floorid, type, onoff?: any) {
    if (onoff !== undefined) {
      return this.http.get(`${BaseUrl}/device/floor/${floorid}/gettype/${type}?online=${parseInt(onoff)}`).pipe(map(this.ResponseInfo));
    } else {
      return this.http.get(`${BaseUrl}/device/floor/${floorid}/gettype/${type}`).pipe(map(this.ResponseInfo));
    }
  }

  /* get the history details for particular device setstate */
  getHistoryDeviceDetails(deviceid, obj) {
    return this.http.post(`${BaseUrl}/event/${deviceid}/state`, obj).pipe(map(this.ResponseInfo));
  }

  uploadLayout(id, type, data) {
    return this.http.post(`${BaseUrl}/upload/${id}/${type}/image`, data);
  }

  deletelayout(id, id1, type) {
    var data = {
      path: true,
    };
    if (type === 'floor') {
      return this.http.put(`${BaseUrl}/location/${id}/update`, data).pipe(map(this.ResponseInfo));
    } else if (type === 'room') {
      return this.http.put(`${BaseUrl}/location/room/${id1}/update/${id}`, data).pipe(map(this.ResponseInfo));
    }

  }
  getbookingHistoryDetails(obj) {
    return this.http.post(`${BaseUrl}/booking/get`, obj).pipe(map(this.ResponseInfo));
  }

  logout() {
    return this.http.get(`${BaseUrl}/user/logout`).pipe(map(this.ResponseInfo));
  }

  /* set state for smartbox controll in roomlevel */
  setStateSmartBox(deviceid, obj, type) {
    return this.http.post(`${BaseUrl}/event/${deviceid}/send/${type}`, obj).pipe(map(this.ResponseInfo));
  }
  /* heatmap energy data */
  getEnergyData(deskid, type, obj) {
    return this.http.post(`${BaseUrl}/energy/desks/${deskid}/${type}`, obj).pipe(map(this.ResponseInfo));
  }

  /* energy minutes */
  getEnergyMinutes(deviceid, obj) {
    return this.http.post(`${BaseUrl}/energy/${deviceid}/minutes`, obj).pipe(map(this.ResponseInfo));
  }

  /* energy hours */
  getEnergyHours(deviceid, obj) {
    return this.http.post(`${BaseUrl}/energy/desks/${deviceid}/hours`, obj).pipe(map(this.ResponseInfo));
  }

  /* energy days */
  getEnergyDays(deviceid, obj) {
    return this.http.post(`${BaseUrl}/energy/desks/${deviceid}/days`, obj).pipe(map(this.ResponseInfo));
  }

  /* energy months */
  getEnergyMonths(deviceid, obj) {
    return this.http.post(`${BaseUrl}/energy/desks/${deviceid}/months`, obj).pipe(map(this.ResponseInfo));
  }

  /* occupancy state */
  getOccState(deviceid, obj) {
    return this.http.post(`${BaseUrl}/occpancy/${deviceid}/state`, obj).pipe(map(this.ResponseInfo));
  }

  /* occupancy days */
  getOccDays(deviceid, obj) {
    return this.http.post(`${BaseUrl}/occpancy/${deviceid}/days`, obj).pipe(map(this.ResponseInfo));
  }

  /* occupancy months*/
  getOccMonths(deviceid, obj) {
    return this.http.post(`${BaseUrl}/occpancy/${deviceid}/months`, obj).pipe(map(this.ResponseInfo));
  }

  /* get devices based on desk id */
  getDevicesOfDesk(deskid) {
    return this.http.get(`${BaseUrl}/desk/devices/${deskid}`).pipe(map(this.ResponseInfo));
  }

  /* get the details of active inactive trending floor/room details */
  getActiveInactiveLocations(locid, name) {
    let start = new Date();
    start.setHours(0, 0, 0, 0);
    return this.http.post(`${BaseUrl}/report/${locid}/last/${name}`, { start: start.getTime() }).pipe(map(this.ResponseInfo));
  }

  /* get day average occupancy data*/
  getAvgOccDay(data, obj, usertype, type) {
    return this.http.post(`${BaseUrl}/report/${data}/${type}/${usertype}`, obj).pipe(map(this.ResponseInfo));
  }

  /* get rgb status for single/multiple sensors */
  getrgbsensorstates(deskid, type, obj) {
    return this.http.post(`${BaseUrl}/report/${deskid}/rgbstate/${type}`, obj).pipe(map(this.ResponseInfo));
  }

  /* get occpancy status for single/multiple sensors */
  getoccpancysensorstates(deviceid, type, obj) {
    return this.http.post(`${BaseUrl}/occpancy/desks/${deviceid}/${type}`, obj).pipe(map(this.ResponseInfo));
  }

  // /* get rgb status total time for devices */
  // getrgbstatesTime(deviceid, type, obj) {
  //   return this.http.post(`${BaseUrl}/report/${deviceid}/rgb/${type}`, obj).pipe(map(this.ResponseInfo));
  // }
  getrgbstatesTime(deskid, type, obj) {
    return this.http.post(`${BaseUrl}/report/${deskid}/rgbdesk/${type}`, obj).pipe(map(this.ResponseInfo));
  }

  /* get the data for heatmaps */
  getheatmapdata(obj, locid, type) {
    return this.http.post(`${BaseUrl}/report/${locid}/heat/${type}`, obj).pipe(map(this.ResponseInfo));
  }

  /* get the occupancy trends data */
  getocctrendsdata(levelid, type, endvalue, obj) {
    return this.http.post(`${BaseUrl}/report/${levelid}/occpancy/${type}/${endvalue}`, obj).pipe(map(this.ResponseInfo));
  }
  getsittingdata(levelid, type, endvalue, obj) {
    return this.http.post(`${BaseUrl}/report/${levelid}/sitting/${type}/${endvalue}`, obj).pipe(map(this.ResponseInfo));
  }
  getsittingsdata(levelid, type, endvalue, obj) {
    return this.http.post(`${BaseUrl}/report/${levelid}/sittings/${type}/${endvalue}`, obj).pipe(map(this.ResponseInfo));
  }
  getpdffile(quary) {
    return this.http.get(`${BaseUrl}/report/reports?${quary}`, { responseType: 'blob' });
  }
  getdeskofsuperlocation(suplocid) {
    return this.http.get(`${BaseUrl}/desk/getsuper/${suplocid}`).pipe(map(this.ResponseInfo));
  }

  addaccesscontrol(obj) {
    return this.http.post(`${BaseUrl}/user/addaccess`, obj).pipe(map(this.ResponseInfo));
  }
  getaccesscontroluser(suplocid) {
    return this.http.get(`${BaseUrl}/user/getaccess/${suplocid}`).pipe(map(this.ResponseInfo));
  }
  deleteaccesscontroluser(suplocid, userid) {
    return this.http.post(`${BaseUrl}/user/deleteaccess/${suplocid}`, userid).pipe(map(this.ResponseInfo));
  }
  updateaccesscontroluser(roomid, obj) {
    return this.http.post(`${BaseUrl}/user/updateaccess/${roomid}`, obj).pipe(map(this.ResponseInfo));
  }

  getnotifications(type, id, obj) {
    return this.http.post(`${BaseUrl}/event/${type}/alarts/${id}`, obj).pipe(map(this.ResponseInfo));
  }
  getActions(type, acttype, id) {
    return this.http.get(`${BaseUrl}/desk/${type}/${acttype}/${id}`).pipe(map(this.ResponseInfo));
  }
  updateActions(type, acttype, id, obj) {
    return this.http.post(`${BaseUrl}/desk/${type}/${acttype}/${id}`, obj).pipe(map(this.ResponseInfo));
  }
  getSubscriptionInformation() {
    return this.http.get(`${BaseUrl}/user/subcription`).pipe(map(this.ResponseInfo));
  }
  //For Firmware
  getFirmwareVersionFromHub(deviceid) {
    return this.http.post(`${BaseUrl}/event/device/${deviceid}/firmware`, {}).pipe(map(this.ResponseInfo));
  }
  clearstorage() {
    localStorage.clear();
    localStorage.setItem('logoutsso', 'true');
  }
}
