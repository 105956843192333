import { Component, OnInit } from '@angular/core';
import { UtilsService } from './services';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Component({
  selector: 'cms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private utils: UtilsService, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    this.utils.getIconData(iconRegistry, sanitizer);
  }
  ngOnInit(): void {
  }
}
