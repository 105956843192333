import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService, UtilsService } from 'src/app/services';

@Component({
  selector: 'cms-session-dialoge',
  templateUrl: './session-dialoge.component.html',
  styleUrls: ['./session-dialoge.component.scss']
})
export class SessionDialogeComponent implements OnInit {
  message;
  loginname = 'OK';
  constructor(private api: ApiService, private dialogRef: MatDialogRef<SessionDialogeComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private route: Router) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    if (this.data.type === 'success') {
      this.message = 'Congratulations! Your password has been changed.you may now login with your new password';
      this.loginname = 'Login';
      this.api.logout().subscribe((data: any) => {
        if (data.status === 1) {
          this.api.clearstorage();
          setTimeout(() => {
            this.dialogRef.close();
            this.route.navigate(['/login']);
          }, 2000);
        }
      });
    } else if (this.data.type === 'session') {
      this.message = this.data.message;
      this.loginname = 'Login';
    } else {
      this.message = this.data.message;
      this.loginname = 'OK';
    }
  }
  public closeMe() {
    this.dialogRef.close({ event: 'close', data: this.data });
  }
}
