import { Injectable, NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    httpOptions: any = { Accept: 'application/json', 'Content-Type': 'application/json' };
    httpOptions2: any = { Accept: 'application/json' };
    constructor() {

    }
    authToken() {
        if (environment.dev) {
            let token: any = localStorage.getItem('ckey');
            if (token !== null && token !== undefined && token.length > 30) {
                this.httpOptions['Authorization'] = token;
            }
        }
        return this.httpOptions;
    }
    authImage() {
        if (environment.dev) {
            let token: any = localStorage.getItem('ckey');
            if (token != undefined && token.length > 30) {
                this.httpOptions2['Authorization'] = token;
            }
        }
        return this.httpOptions2;
    }
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if ((request.url).indexOf('/upload/') > -1) {
            request = request.clone({
                setHeaders: this.authImage(),
            });
        } else {
            request = request.clone({
                setHeaders: this.authToken(),
            });
        }
        return next.handle(request);
    }
}
