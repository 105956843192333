import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { LoginComponent } from '../app/admin/login/login.component';
import { ForgotpasswordComponent } from '../app/admin/forgotpassword/forgotpassword.component';
import { OtpComponent } from './admin/otp/otp.component';
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgot-password/:type',
    component: ForgotpasswordComponent,
  },
  {
    path: 'otp',
    component: OtpComponent,
  },
  {
    path: 'occupancy',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];
const config: ExtraOptions = {
  useHash: true, onSameUrlNavigation: 'reload',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
