import { Output, EventEmitter, Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class EventemitterService {
    @Output() messageEvent = new EventEmitter<string>();
    datatext;
    constructor() {}
    sendmessage(message) {
        this.messageEvent.emit(message);
    }
}
