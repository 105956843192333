import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { formatDate } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { NavigationEnd, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UtilsService extends ApiService {

  private fullscreenMessage = new BehaviorSubject(true);
  private session = new Subject<any>();
  sessiontimeout = this.session.asObservable();
  changeImage = this.fullscreenMessage.asObservable();
  locationId;
  floorId;
  imagesite = environment.imagesite;
  loginUserDetails;
  selectedfloor;

  constructor(public api: ApiService, public route: Router, public http: HttpClient, public dialog: MatDialog, private snackbar: MatSnackBar, private toastr: ToastrService) {
    super(http, route, dialog);
    this.locationId = this.route.url.split('/');
    this.route.events.subscribe((val: NavigationEnd) => {
      if (val instanceof NavigationEnd) {
        let floor = val.url.split('/');
        if (floor[2] === 'floor') {
          this.selectedfloor = floor[4];
        }
      }
    });
  }
  getIconData(iconRegistry, sanitizer) {
    iconRegistry.addSvgIcon('calendar', sanitizer.bypassSecurityTrustResourceUrl('assets/images/calendar.svg'));
    iconRegistry.addSvgIcon('task-complete-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/Mark as complete.svg'));
    iconRegistry.addSvgIcon('search', sanitizer.bypassSecurityTrustResourceUrl('assets/images/search.svg'));
    iconRegistry.addSvgIcon('download', sanitizer.bypassSecurityTrustResourceUrl('assets/images/download.svg'));
    iconRegistry.addSvgIcon('arrow-back', sanitizer.bypassSecurityTrustResourceUrl('assets/images/arrow_back-24px.svg'));
    iconRegistry.addSvgIcon('arrow-forward', sanitizer.bypassSecurityTrustResourceUrl('assets/images/arrow_forward-24px.svg'));
    iconRegistry.addSvgIcon('arrow-right', sanitizer.bypassSecurityTrustResourceUrl('assets/images/arrow_right-24px.svg'));
    iconRegistry.addSvgIcon('clockicon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/work in progress & reopen.svg'));
    iconRegistry.addSvgIcon('message-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/review.svg'));
    iconRegistry.addSvgIcon('settings', sanitizer.bypassSecurityTrustResourceUrl('assets/images/inner-settings.svg'));
    iconRegistry.addSvgIcon('motion', sanitizer.bypassSecurityTrustResourceUrl('assets/images/motion.svg'));
    iconRegistry.addSvgIcon('overview', sanitizer.bypassSecurityTrustResourceUrl('assets/images/overview.svg'));
    iconRegistry.addSvgIcon('layouts', sanitizer.bypassSecurityTrustResourceUrl('assets/images/layouts.svg'));
    iconRegistry.addSvgIcon('settings', sanitizer.bypassSecurityTrustResourceUrl('assets/images/settings.svg'));
    iconRegistry.addSvgIcon('ON', sanitizer.bypassSecurityTrustResourceUrl('assets/images/ON.svg'));
    iconRegistry.addSvgIcon('OFF', sanitizer.bypassSecurityTrustResourceUrl('assets/images/Off.svg'));
    iconRegistry.addSvgIcon('home', sanitizer.bypassSecurityTrustResourceUrl('assets/images/home_icon.svg'));
    iconRegistry.addSvgIcon('energy', sanitizer.bypassSecurityTrustResourceUrl('assets/images/energy_icon.svg'));
    iconRegistry.addSvgIcon('occupency', sanitizer.bypassSecurityTrustResourceUrl('assets/images/occupency_icon.svg'));
    iconRegistry.addSvgIcon('devicecontrol', sanitizer.bypassSecurityTrustResourceUrl('assets/images/devicecontrol_icon.svg'));
    iconRegistry.addSvgIcon('accesscontrol', sanitizer.bypassSecurityTrustResourceUrl('assets/images/accesscontrol_icon.svg'));
    iconRegistry.addSvgIcon('security', sanitizer.bypassSecurityTrustResourceUrl('assets/images/security_icon.svg'));
    iconRegistry.addSvgIcon('weather', sanitizer.bypassSecurityTrustResourceUrl('assets/images/weather_icon.svg'));
    iconRegistry.addSvgIcon('water', sanitizer.bypassSecurityTrustResourceUrl('assets/images/water_icon.svg'));
    iconRegistry.addSvgIcon('hub', sanitizer.bypassSecurityTrustResourceUrl('assets/images/edge02.svg'));
    iconRegistry.addSvgIcon('settingsnavbar', sanitizer.bypassSecurityTrustResourceUrl('assets/images/settingnavbar_icon.svg'));
    iconRegistry.addSvgIcon('notificationnavbar', sanitizer.bypassSecurityTrustResourceUrl('assets/images/notification_navbar_icon.svg'));
    iconRegistry.addSvgIcon('profilenavbar', sanitizer.bypassSecurityTrustResourceUrl('assets/images/profilenavbar_icon.svg'));
    iconRegistry.addSvgIcon('negative', sanitizer.bypassSecurityTrustResourceUrl('assets/images/negative_delete.svg'));
    iconRegistry.addSvgIcon('ints', sanitizer.bypassSecurityTrustResourceUrl('assets/images/inner-settings.svg'));
    iconRegistry.addSvgIcon('full-screen', sanitizer.bypassSecurityTrustResourceUrl('assets/images/fullscreen.svg'));
    iconRegistry.addSvgIcon('zoom-in', sanitizer.bypassSecurityTrustResourceUrl('assets/images/zoomin.svg'));
    iconRegistry.addSvgIcon('zoom-out', sanitizer.bypassSecurityTrustResourceUrl('assets/images/zoomout.svg'));
    iconRegistry.addSvgIcon('exit-screen', sanitizer.bypassSecurityTrustResourceUrl('assets/images/exit-screen.svg'));
    iconRegistry.addSvgIcon('dragDrop', sanitizer.bypassSecurityTrustResourceUrl('assets/images/drag_drop_icon.svg'));
    iconRegistry.addSvgIcon('refresh', sanitizer.bypassSecurityTrustResourceUrl('assets/images/Refresh.svg'));
    iconRegistry.addSvgIcon('InUse', sanitizer.bypassSecurityTrustResourceUrl('assets/images/inuse.svg'));
    iconRegistry.addSvgIcon('Available', sanitizer.bypassSecurityTrustResourceUrl('assets/images/available1.svg'));
    iconRegistry.addSvgIcon('wrong', sanitizer.bypassSecurityTrustResourceUrl('assets/images/wrongtick.svg'));
    iconRegistry.addSvgIcon('right', sanitizer.bypassSecurityTrustResourceUrl('assets/images/righttick.svg'));
    iconRegistry.addSvgIcon('forward', sanitizer.bypassSecurityTrustResourceUrl('assets/images/Forward.svg'));
    iconRegistry.addSvgIcon('backward', sanitizer.bypassSecurityTrustResourceUrl('assets/images/Back.svg'));
    iconRegistry.addSvgIcon('floor', sanitizer.bypassSecurityTrustResourceUrl('assets/images/Floors.svg'));
    iconRegistry.addSvgIcon('location', sanitizer.bypassSecurityTrustResourceUrl('assets/images/Locations.svg'));
    iconRegistry.addSvgIcon('room', sanitizer.bypassSecurityTrustResourceUrl('assets/images/Rooms.svg'));
    iconRegistry.addSvgIcon('sort', sanitizer.bypassSecurityTrustResourceUrl('assets/images/sort.svg'));
    iconRegistry.addSvgIcon('penedit', sanitizer.bypassSecurityTrustResourceUrl('assets/images/penedit.svg'));
    iconRegistry.addSvgIcon('Locked', sanitizer.bypassSecurityTrustResourceUrl('assets/images/Locked.svg'));
    iconRegistry.addSvgIcon('Unlocked', sanitizer.bypassSecurityTrustResourceUrl('assets/images/Unlocked.svg'));
    iconRegistry.addSvgIcon('Info', sanitizer.bypassSecurityTrustResourceUrl('assets/images/Info.svg'));
    iconRegistry.addSvgIcon('logo', sanitizer.bypassSecurityTrustResourceUrl('assets/images/userdashboardlogo.svg'));
    iconRegistry.addSvgIcon('addicon', sanitizer.bypassSecurityTrustResourceUrl('assets/images/addicon.svg'));
    iconRegistry.addSvgIcon('lock', sanitizer.bypassSecurityTrustResourceUrl('assets/images/lock.svg'));
    iconRegistry.addSvgIcon('unlock', sanitizer.bypassSecurityTrustResourceUrl('assets/images/unlock.svg'));
    iconRegistry.addSvgIcon('delete', sanitizer.bypassSecurityTrustResourceUrl('assets/images/delete.svg'));
    iconRegistry.addSvgIcon('switch_dashboard', sanitizer.bypassSecurityTrustResourceUrl('assets/images/switch_dashboard.svg'));
  }

  menulist = [];

  public getDetails(type, lid, fid, cb) {
    if (this.menulist !== undefined) {
      if (type === '0') {
        this.menulist.forEach(ele => {
          if (ele.id === lid) {
            cb(ele);
          }
        });
      } else if (type === '1') {
        this.menulist.forEach(ele => {
          if (ele.id === lid) {
            ele.children.forEach(ch => {
              if (ch.id === fid) {
                cb(ch);
              }
            });
          }
        });
      } else if (type === '2') {
        cb(this.menulist);
      }
    }
  }
  getLocations(cb) {
    let resp;
    this.api.getlocation().subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false);
      }
    });
  }
  getFirmware(id: any, cb) {
    let response;
    this.api.getFirmwareVersionFromHub(id).subscribe(data => {
      response = data;
      if (response.status === 1) {
        cb(true, response.data);
      } else {
        cb(false);
      }

    });
  }

  changeMessage(message) {
    this.fullscreenMessage.next(message);
  }

  public OccepancyAll = [
    { loc: 'Location 1', seats: 23, total: 30, state: '1' },
    { loc: 'Location 5', seats: 23, total: 30, state: '1' },
    { loc: 'Location 11', seats: 23, total: 30, state: '1' },
    { loc: 'Location 3', seats: 14, total: 20, state: '1' },
    { loc: 'Location 7', seats: 14, total: 20, state: '1' },
    { loc: 'Location 9', seats: 14, total: 20, state: '1' },
    { loc: 'Location 13', seats: 14, total: 20, state: '0' },
    { loc: 'Location 4', seats: 20, total: 33, state: '1' },
    { loc: 'Location 10', seats: 20, total: 33, state: '1' },
    { loc: 'Location 14', seats: 20, total: 33, state: '0' },
    { loc: 'Location 8', seats: 20, total: 33, state: '1' },
    { loc: 'Location 6', seats: 7, total: 13, state: '1' },
    { loc: 'Location 2', seats: 7, total: 13, state: '0' },
    { loc: 'Location 12', seats: 7, total: 13, state: '0' },
  ];
  public schedules = [
    { name: 'Weekday', time: '10:00 Am - 07:00 Pm', status: true },
    { name: 'Weekend', time: '11:00 Am - 11:30 Pm', status: false },
  ];
  public accessControlData = [
    { name: 'Floor 1', areas: ['Reception area', 'Work space'], members: [{ name: `Floor 1 manager / f1m@gmail.com, Receptionist / f1r@gmail.com`, id1: '2', id2: '0', id3: '0', id4: '1', id5: '2', id6: '2' }] },
    { name: 'Floor 2', areas: ['Conference room', 'Floor 2 cafeteria'], members: [{ name: `Floor 2 manager / f2m@gmail.com, HR technical team / hrtech@gmail.com`, id1: '1', id2: '1', id3: '0', id4: '1', id5: '2', id6: '2' }] },
  ];

  public floorInfo = ['Floor 1', 'Reception area', 'workspace'];
  getHeadingNames(url, cb) {
    const respObject: any = {};
    if (url.length > 0) {
      if (url === 'dashboard') {
        respObject.activeheading = 'Active Locations';
        respObject.trending = 'location';
        respObject.occtrend_tableheading = 'Location Name';
        respObject.listheading = 'Location';
      } else if (url === 'location') {
        respObject.activeheading = 'Active Floors';
        respObject.trending = 'floor';
        respObject.occtrend_tableheading = 'Floor Name';
        respObject.listheading = 'Floor';
      } else if (url === 'floor') {
        respObject.activeheading = 'Active Areas';
        respObject.occtrend_tableheading = 'Area Name';
        respObject.trending = 'area';
        respObject.listheading = 'Area';
      }
      cb(true, respObject);
    } else {
      cb(false);
    }

  }

  public durations = ['Day', 'Last 7 days', 'Month', 'Year'];
  public downloadAsPDF(input, name, scrolldiv, scrolldivHeight, cb) {
    let ratio = 0;
    let mode: any = 'landscape';
    const divHeight = input.clientHeight;
    const divWidth = input.clientWidth;
    ratio = divHeight / divWidth;
    if (divHeight > 960) {
      mode = 'portrait';
    }
    if (scrolldiv !== '') {
      document.getElementById(scrolldiv).style.height = 'auto';
      if (name) {
        var trend: any = document.getElementsByClassName('th');
        if (trend) {
          for (var i = 0; i < trend.length; i++) {
            trend[i].style.width = '10%';
          }
        }
      }
    }
    html2canvas(input, { scale: 2 }).then(canvas => {
      // let fileWidth = (mode !== 'landscape') ? 208 : 295;
      // let fileHeight = canvas.height * fileWidth / canvas.width;
      // const FILEURI = canvas.toDataURL('image/png');
      // let PDF = new jsPDF(mode, 'mm', 'a4');
      // PDF.addImage(FILEURI, 'PNG', 0, 0, fileWidth, fileHeight);+
      var imgData = canvas.toDataURL('image/png');
      var fileWidth = (mode !== 'landscape') ? 210 : 295;
      var pageHeight = 295;
      var fileHeight = canvas.height * fileWidth / canvas.width;
      var heightLeft = fileHeight;
      var doc = new jsPDF(mode, 'mm');
      var position = 5;
      doc.addImage(imgData, 'PNG', 0, position, fileWidth, fileHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position += heightLeft - fileHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, fileWidth, fileHeight);
        heightLeft -= pageHeight;
      }
      doc.save(`${name}.pdf`);
      // PDF.save(`${name}.pdf`);
      cb(true);
    });
    if (scrolldiv !== '') {
      document.getElementById(scrolldiv).style.height = `${scrolldivHeight}` + 'px';
      if (name) {
        var trend: any = document.getElementsByClassName('th');
        if (trend) {
          for (var i = 0; i < trend.length; i++) {
            trend[i].style.width = '100px';
          }
        }
      }
    }
  }
  // public Daily = ['Daily', 'Weekly', 'Monthly'];
  public Daily = ['Daily'];
  public Bookinghistory = ['Booking history', 'Future bookings'];
  public fromTo = ['From & To', 'Quarterly', 'Yearly'];
  public floorType = [
    { floor_name: 'Floor 1', id: '1', areas: [{ area_name: 'Reception area', id: '11' }, { area_name: 'Workspace', id: '12' }] },
    { floor_name: 'Floor 2', id: '2', areas: [{ area_name: 'Conference room', id: '21' }, { area_name: 'Floor 2 Cafeteria', id: '22' }] }
  ];
  floornames = [];
  a = [];
  public Desk = ['Desk1', 'Desk2', 'Desk3', 'Desk4'];

  public locationData = ['Location1', 'Location2', 'Location3', 'Location4'];
  public FloorData = ['Floor1', 'Floor2', 'Floor3'];
  public workspaceData = ['Conference room', 'Conference room 2', 'Workspace', 'Workspace 1'];
  public DeskData = ['Desk1', 'Desk2', 'Desk3', 'Desk4'];

  getFloorType() {
    this.floorType.forEach((res, index) => {
      this.floornames.push({ name: res.floor_name, id: res.id });
      if ((res.areas).length > 0) {
        for (var i = 0; i < (res.areas).length; i++) {
          this.floornames.push({ name: res.areas[i].area_name, id: res.areas[i].id });
        }
      }
    });
    for (var i = 0; i < this.floornames.length; i++) {
      this.a.push(this.floornames[i].name);
    }
    return this.a;
  }

  public quaterly = ['1st quarter', '2nd quarter', '3rd quarter', '4th quarter'];
  public reportIncludes = ['Average occupancy', 'Energy data'];
  public selectList = ['Hour', 'Day', 'Last 7 days', 'Month', 'Year'];
  public selectionList = ['Day', 'Last 7 days', 'Month', 'Year'];
  typeDate = new Date();
  public mlist = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  date: any;
  maxDate = new Date();
  createDate = new Date(1600000044800);

  daycalculation(selectedType, buttonType, selectedDay, createdMeterDate, cb) {
    let nextbutton, prevbutton, disableNextButton, disablePrevButton;
    if (selectedType === 'Day') {
      if (new Date((selectedDay.getDate()) + ' ' + this.mlist[selectedDay.getMonth()] + ',' + ' ' + selectedDay.getFullYear()) >= new Date(new Date().getDate() + ' ' + this.mlist[new Date().getMonth()] + ',' + ' ' + new Date().getFullYear()) && (buttonType === 'nextButton')) {
        this.typeDate = selectedDay;
        nextbutton = false;
        disableNextButton = true;
        prevbutton = true;
        disablePrevButton = false;
        cb(false, nextbutton, disableNextButton, prevbutton, disablePrevButton, selectedDay);
      } else if (new Date((selectedDay.getDate()) + ' ' + this.mlist[selectedDay.getMonth()] + ',' + ' ' + selectedDay.getFullYear()) <= (new Date(createdMeterDate)) && (buttonType === 'prevButton')) {
        this.typeDate = selectedDay;
        nextbutton = true;
        disableNextButton = false;
        prevbutton = false;
        disablePrevButton = true;
        cb(false, nextbutton, disableNextButton, prevbutton, disablePrevButton, selectedDay);
      } else {
        if (buttonType === 'nextButton') {
          selectedDay.setDate(selectedDay.getDate() + 1);
        } else if (buttonType === 'prevButton') {
          selectedDay.setDate(selectedDay.getDate() - 1);
        } else {
          selectedDay.setDate(selectedDay.getDate());
        }
        this.typeDate = selectedDay;
        if (new Date((selectedDay.getDate()) + ' ' + this.mlist[selectedDay.getMonth()] + ',' + ' ' + selectedDay.getFullYear()) >= new Date(new Date().getDate() + ' ' + this.mlist[new Date().getMonth()] + ',' + ' ' + new Date().getFullYear()) && (buttonType === 'nextButton')) {
          nextbutton = false;
          disableNextButton = true;
          prevbutton = true;
          disablePrevButton = false;
        } else if (new Date((selectedDay.getDate()) + ' ' + this.mlist[selectedDay.getMonth()] + ',' + ' ' + selectedDay.getFullYear()) <= (new Date(createdMeterDate)) && (buttonType === 'prevButton')) {
          nextbutton = true;
          disableNextButton = false;
          prevbutton = false;
          disablePrevButton = true;
        } else if ((new Date((selectedDay.getDate()) + ' ' + this.mlist[selectedDay.getMonth()] + ',' + ' ' + selectedDay.getFullYear())) >= new Date(new Date().getDate() + ' ' + this.mlist[new Date().getMonth()] + ',' + ' ' + new Date().getFullYear()) && (new Date((selectedDay.getDate()) + ' ' + this.mlist[selectedDay.getMonth()] + ',' + ' ' + selectedDay.getFullYear()) <= new Date(createdMeterDate))) {
          nextbutton = false;
          disableNextButton = true;
          prevbutton = false;
          disablePrevButton = true;
        } else if ((new Date((selectedDay.getDate()) + ' ' + this.mlist[selectedDay.getMonth()] + ',' + ' ' + selectedDay.getFullYear()) !== new Date(new Date().getDate() + ' ' + this.mlist[new Date().getMonth()] + ',' + ' ' + new Date().getFullYear())) && (new Date((selectedDay.getDate()) + ' ' + this.mlist[selectedDay.getMonth()] + ',' + ' ' + selectedDay.getFullYear()) <= new Date(createdMeterDate))) {
          nextbutton = true;
          disableNextButton = false;
          prevbutton = false;
          disablePrevButton = true;
        } else if (((new Date((selectedDay.getDate()) + ' ' + this.mlist[selectedDay.getMonth()] + ',' + ' ' + selectedDay.getFullYear()) >= new Date(new Date().getDate() + ' ' + this.mlist[new Date().getMonth()] + ',' + ' ' + new Date().getFullYear())) && (new Date((selectedDay.getDate()) + ' ' + this.mlist[selectedDay.getMonth()] + ',' + ' ' + selectedDay.getFullYear()) >= new Date(createdMeterDate)))) {
          nextbutton = false;
          disableNextButton = true;
          prevbutton = true;
          disablePrevButton = false;
        } else if ((new Date((selectedDay.getDate()) + ' ' + this.mlist[selectedDay.getMonth()] + ',' + ' ' + selectedDay.getFullYear()) !== new Date(new Date().getDate() + ' ' + this.mlist[new Date().getMonth()] + ',' + ' ' + new Date().getFullYear())) && (new Date((selectedDay.getDate()) + ' ' + this.mlist[selectedDay.getMonth()] + ',' + ' ' + selectedDay.getFullYear()) === new Date(createdMeterDate))) {
          nextbutton = true;
          disableNextButton = false;
          prevbutton = false;
          disablePrevButton = true;
        }
        cb(true, nextbutton, disableNextButton, prevbutton, disablePrevButton, selectedDay);
      }
    } else if (selectedType === 'Month') {
      if (selectedDay.getMonth() >= new Date().getMonth() && selectedDay.getFullYear() >= new Date().getFullYear() && (buttonType === 'nextButton')) {
        this.typeDate = selectedDay;
        nextbutton = false;
        disableNextButton = true;
        prevbutton = true;
        disablePrevButton = false;
        cb(false, nextbutton, disableNextButton, prevbutton, disablePrevButton, selectedDay);
      } else if (new Date(this.mlist[selectedDay.getMonth()] + ',' + ' ' + selectedDay.getFullYear()) <= (new Date(this.mlist[createdMeterDate.getMonth()] + ',' + ' ' + createdMeterDate.getFullYear())) && (buttonType === 'prevButton')) {
        this.typeDate = selectedDay;
        nextbutton = true;
        disableNextButton = false;
        prevbutton = false;
        disablePrevButton = true;
        cb(false, nextbutton, disableNextButton, prevbutton, disablePrevButton, selectedDay);
      } else {
        if (buttonType === 'nextButton') {
          selectedDay.setMonth(selectedDay.getMonth() + 1);
        } else if (buttonType === 'prevButton') {
          selectedDay.setMonth(selectedDay.getMonth() - 1);
        } else {
          selectedDay.setMonth(selectedDay.getMonth());
        }
        this.typeDate = selectedDay;
        if (selectedDay.getMonth() >= new Date().getMonth() && selectedDay.getFullYear() >= new Date().getFullYear() && (buttonType === 'nextButton')) {

          nextbutton = false;
          disableNextButton = true;
          prevbutton = true;
          disablePrevButton = false;

        } else if (new Date(this.mlist[selectedDay.getMonth()] + ',' + ' ' + selectedDay.getFullYear()) <= (new Date(this.mlist[createdMeterDate.getMonth()] + ',' + ' ' + createdMeterDate.getFullYear())) && (buttonType === 'prevButton')) {

          nextbutton = true;
          disableNextButton = false;
          prevbutton = false;
          disablePrevButton = true;

        } else if (selectedDay.getMonth() === new Date().getMonth() && selectedDay.getFullYear() === new Date().getFullYear() && selectedDay.getMonth() === createdMeterDate.getMonth() && selectedDay.getFullYear() === createdMeterDate.getFullYear()) {

          nextbutton = false;
          disableNextButton = true;
          prevbutton = false;
          disablePrevButton = true;

        } else if ((selectedDay.getMonth() <= new Date().getMonth() && selectedDay.getFullYear() <= new Date().getFullYear()) && (selectedDay.getMonth() <= createdMeterDate.getMonth() && selectedDay.getFullYear() <= createdMeterDate.getFullYear())) {
          nextbutton = true;
          disableNextButton = false;
          prevbutton = false;
          disablePrevButton = true;
        } else if ((selectedDay.getMonth() >= new Date().getMonth() && selectedDay.getFullYear() >= new Date().getFullYear()) && (selectedDay.getMonth() >= createdMeterDate.getMonth() && selectedDay.getFullYear() >= createdMeterDate.getFullYear())) {
          nextbutton = false;
          disableNextButton = true;
          prevbutton = true;
          disablePrevButton = false;
        } else if ((selectedDay.getMonth() !== new Date().getMonth() && selectedDay.getFullYear() !== new Date().getFullYear()) && (selectedDay.getMonth() >= createdMeterDate.getMonth() && selectedDay.getFullYear() >= createdMeterDate.getFullYear())) {

          nextbutton = true;
          disableNextButton = false;
          prevbutton = true;
          disablePrevButton = false;
        }
        cb(true, nextbutton, disableNextButton, prevbutton, disablePrevButton, selectedDay);
      }
    } else if (selectedType === 'Year') {
      if ((selectedDay.getFullYear() >= (new Date().getFullYear())) && (buttonType === 'nextButton')) {
        this.typeDate = selectedDay;
        nextbutton = false;
        disableNextButton = true;
        prevbutton = true;
        disablePrevButton = false;
        cb(false, nextbutton, disableNextButton, prevbutton, disablePrevButton, selectedDay);
      } else if (selectedDay.getFullYear() <= (createdMeterDate.getFullYear()) && (buttonType === 'prevButton')) {
        this.typeDate = selectedDay;
        nextbutton = true;
        disableNextButton = false;
        prevbutton = false;
        disablePrevButton = true;
        cb(false, nextbutton, disableNextButton, prevbutton, disablePrevButton, selectedDay);
      } else {
        if (buttonType === 'nextButton') {
          selectedDay.setFullYear(selectedDay.getFullYear() + 1);
        } else if (buttonType === 'prevButton') {
          selectedDay.setFullYear(selectedDay.getFullYear() - 1);
        } else {
          selectedDay.setFullYear(selectedDay.getFullYear());
        }
        this.typeDate = selectedDay;
        if ((selectedDay.getFullYear() >= (new Date().getFullYear())) && (buttonType === 'nextButton')) {

          nextbutton = false;
          disableNextButton = true;
          prevbutton = true;
          disablePrevButton = false;

        } else if (selectedDay.getFullYear() <= (createdMeterDate.getFullYear()) && (buttonType === 'prevButton')) {

          nextbutton = true;
          disableNextButton = false;
          prevbutton = false;
          disablePrevButton = true;

        } else if ((selectedDay.getFullYear() === new Date().getFullYear()) && (selectedDay.getFullYear() === createdMeterDate.getFullYear())) {
          nextbutton = false;
          disableNextButton = true;
          prevbutton = false;
          disablePrevButton = true;
        } else if (((selectedDay.getFullYear() <= new Date().getFullYear()) && (selectedDay.getFullYear() <= createdMeterDate.getFullYear()))) {
          nextbutton = true;
          disableNextButton = false;
          prevbutton = false;
          disablePrevButton = true;
        } else if (((selectedDay.getFullYear() >= new Date().getFullYear()) && (selectedDay.getFullYear() >= createdMeterDate.getFullYear()))) {
          nextbutton = false;
          disableNextButton = true;
          prevbutton = true;
          disablePrevButton = false;
        } else if ((selectedDay.getFullYear() != new Date().getFullYear()) && (selectedDay.getFullYear() >= createdMeterDate.getFullYear())) {

          nextbutton = true;
          disableNextButton = false;
          prevbutton = true;
          disablePrevButton = false;
        }
        cb(true, nextbutton, disableNextButton, prevbutton, disablePrevButton, selectedDay);
      }
    }
  }

  weekcalculation(selectedType, buttonType, beforeDate, createdMeterDate, cb) {
    let nextbutton, prevbutton, disableNextButton, disablePrevButton, afterDate, startdate1, newdate1;
    if (selectedType === 'Last 7 days') {
      startdate1 = new Date(beforeDate);
      newdate1 = new Date(startdate1);
      newdate1.setDate(newdate1.getDate() + 6);
      afterDate = newdate1;
      if (new Date(newdate1.getUTCDate() + ' ' + this.mlist[newdate1.getUTCMonth()] + ',' + ' ' + newdate1.getUTCFullYear()) >= new Date(new Date().getUTCDate() - 1 + ' ' + this.mlist[new Date().getUTCMonth()] + ',' + ' ' + new Date().getUTCFullYear()) && (buttonType === 'nextButton')) {

        nextbutton = false;
        disableNextButton = true;
        prevbutton = true;
        disablePrevButton = false;
        cb(false, nextbutton, disableNextButton, prevbutton, disablePrevButton, beforeDate, afterDate);
      } else if (new Date(beforeDate.getUTCDate() + ' ' + this.mlist[beforeDate.getUTCMonth()] + ',' + ' ' + beforeDate.getUTCFullYear()) <= (createdMeterDate) && (buttonType === 'prevButton')) {
        nextbutton = true;
        disableNextButton = false;
        prevbutton = false;
        disablePrevButton = true;
        cb(false, nextbutton, disableNextButton, prevbutton, disablePrevButton, beforeDate, afterDate);
      } else {
        if (buttonType === 'nextButton') {
          beforeDate.setDate(beforeDate.getDate() + 7);
          beforeDate.setUTCHours(0, 0, 0, 0);
          afterDate.setDate(afterDate.getDate() + 7);
          afterDate.setUTCHours(0, 0, 0, 0);
        } else if (buttonType === 'prevButton') {
          beforeDate.setUTCDate(beforeDate.getUTCDate() - 7);
          beforeDate.setUTCHours(0, 0, 0, 0);
          afterDate.setUTCDate(afterDate.getUTCDate() - 7);
          afterDate.setUTCHours(0, 0, 0, 0);
        } else {
          if (beforeDate <= createdMeterDate) {
            const startdate3: any = new Date(beforeDate);
            const newdate3: any = new Date(startdate3);
            newdate3.setDate(newdate3.getDate() + 1);
            beforeDate = new Date(newdate3);
          }
          beforeDate = beforeDate;
          beforeDate.setUTCFullYear(beforeDate.getFullYear(), beforeDate.getMonth(), beforeDate.getDate() - 7);
          beforeDate.setUTCHours(0, 0, 0, 0);
          let startdate: any = new Date(beforeDate);
          const newdate2: any = new Date(startdate);
          newdate2.setDate(newdate2.getDate() + 6);
          afterDate = newdate2;
        }
        if (new Date(afterDate.getUTCDate() + ' ' + this.mlist[afterDate.getUTCMonth()] + ',' + ' ' + afterDate.getUTCFullYear()) >= new Date(new Date().getUTCDate() - 1 + ' ' + this.mlist[new Date().getUTCMonth()] + ',' + ' ' + new Date().getUTCFullYear()) && (buttonType === 'nextButton')) {

          nextbutton = false;
          disableNextButton = true;
          prevbutton = true;
          disablePrevButton = false;
        } else if (new Date(beforeDate.getUTCDate() + ' ' + this.mlist[beforeDate.getUTCMonth()] + ',' + ' ' + beforeDate.getUTCFullYear()) <= (createdMeterDate) && (buttonType === 'prevButton')) {

          nextbutton = true;
          disableNextButton = false;
          prevbutton = false;
          disablePrevButton = true;
        } else if ((new Date(afterDate.getUTCDate() + ' ' + this.mlist[afterDate.getUTCMonth()] + ',' + ' ' + afterDate.getUTCFullYear())) >= new Date(new Date().getUTCDate() - 1 + ' ' + this.mlist[new Date().getUTCMonth()] + ',' + ' ' + new Date().getUTCFullYear())
          && ((new Date(beforeDate.getUTCDate() + ' ' + this.mlist[beforeDate.getUTCMonth()] + ',' + ' ' + beforeDate.getUTCFullYear())) >= new Date(createdMeterDate.getUTCDate() + ' ' + this.mlist[createdMeterDate.getUTCMonth()] + ',' + ' ' + createdMeterDate.getUTCFullYear()))) {

          nextbutton = false;
          disableNextButton = true;
          prevbutton = true;
          disablePrevButton = false;
        } else if ((new Date(afterDate.getUTCDate() + ' ' + this.mlist[afterDate.getUTCMonth()] + ',' + ' ' + afterDate.getUTCFullYear())) <= new Date(new Date().getUTCDate() + ' ' + this.mlist[new Date().getUTCMonth()] + ',' + ' ' + new Date().getUTCFullYear())
          && ((new Date(beforeDate.getUTCDate() + ' ' + this.mlist[beforeDate.getUTCMonth()] + ',' + ' ' + beforeDate.getUTCFullYear())) >= new Date(createdMeterDate.getUTCDate() + ' ' + this.mlist[createdMeterDate.getUTCMonth()] + ',' + ' ' + createdMeterDate.getUTCFullYear()))) {

          nextbutton = true;
          disableNextButton = false;
          prevbutton = true;
          disablePrevButton = false;
        } else if ((new Date(afterDate.getUTCDate() + ' ' + this.mlist[afterDate.getUTCMonth()] + ',' + ' ' + afterDate.getUTCFullYear())) <= new Date(new Date().getUTCDate() + ' ' + this.mlist[new Date().getUTCMonth()] + ',' + ' ' + new Date().getUTCFullYear())
          && ((new Date(beforeDate.getUTCDate() + ' ' + this.mlist[beforeDate.getUTCMonth()] + ',' + ' ' + beforeDate.getUTCFullYear())) <= new Date(createdMeterDate.getUTCDate() + ' ' + this.mlist[createdMeterDate.getUTCMonth()] + ',' + ' ' + createdMeterDate.getUTCFullYear()))) {

          nextbutton = true;
          disableNextButton = false;
          prevbutton = false;
          disablePrevButton = true;
        } else if ((new Date(afterDate.getUTCDate() + ' ' + this.mlist[afterDate.getUTCMonth()] + ',' + ' ' + afterDate.getUTCFullYear())) >= new Date(new Date().getUTCDate() + ' ' + this.mlist[new Date().getUTCMonth()] + ',' + ' ' + new Date().getUTCFullYear())
          && ((new Date(beforeDate.getUTCDate() + ' ' + this.mlist[beforeDate.getUTCMonth()] + ',' + ' ' + beforeDate.getUTCFullYear())) >= new Date(createdMeterDate.getUTCDate() + ' ' + this.mlist[createdMeterDate.getUTCMonth()] + ',' + ' ' + createdMeterDate.getUTCFullYear()))) {

          nextbutton = false;
          disableNextButton = true;
          prevbutton = true;
          disablePrevButton = false;
        }
        cb(true, nextbutton, disableNextButton, prevbutton, disablePrevButton, beforeDate, afterDate);
      }
    }
  }
  hourcalculation(selectedType, buttonType, selectedHour, createdMeterDate, cb) {
    let nextbutton, prevbutton, disableNextButton, disablePrevButton, hours, ampm;
    hours = selectedHour.getHours();
    ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    if (formatDate(selectedHour, 'dd-MM-yyyy hh a', 'en-US', '+0530') === formatDate(new Date(), 'dd-MM-yyyy hh a', 'en-US', '+0530') && (buttonType === 'nextHour')) {
      this.typeDate = selectedHour;
      nextbutton = false;
      disableNextButton = true;
      prevbutton = true;
      disablePrevButton = false;
      cb(false, nextbutton, disableNextButton, prevbutton, disablePrevButton, selectedHour, ampm, hours);
    } else if (formatDate(selectedHour, 'dd-MM-yyyy hh a', 'en-US', '+0530') === formatDate(createdMeterDate, 'dd-MM-yyyy hh a', 'en-US', '+0530') && (buttonType === 'prevHour')) {
      this.typeDate = selectedHour;
      nextbutton = true;
      disableNextButton = false;
      prevbutton = false;
      disablePrevButton = true;
      cb(false, nextbutton, disableNextButton, prevbutton, disablePrevButton, selectedHour, ampm, hours);
    } else {
      if (buttonType === 'nextHour') {
        selectedHour.setHours(selectedHour.getHours() + 1);
      } else if (buttonType === 'prevHour') {
        selectedHour.setHours(selectedHour.getHours() - 1);
      } else {

      }
      hours = selectedHour.getHours();
      ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12;
      if (formatDate(selectedHour, 'dd-MM-yyyy hh a', 'en-US', '+0530') === formatDate(new Date(), 'dd-MM-yyyy hh a', 'en-US', '+0530') && (buttonType === 'nextHour')) {
        nextbutton = false;
        disableNextButton = true;
        prevbutton = true;
        disablePrevButton = false;
      } else if (formatDate(selectedHour, 'dd-MM-yyyy hh a', 'en-US', '+0530') === formatDate(createdMeterDate, 'dd-MM-yyyy hh a', 'en-US', '+0530') && (buttonType === 'prevHour')) {
        nextbutton = true;
        disableNextButton = false;
        prevbutton = false;
        disablePrevButton = true;
      } else if (formatDate(selectedHour, 'dd-MM-yyyy hh a', 'en-US', '+0530') === formatDate(new Date(), 'dd-MM-yyyy hh a', 'en-US', '+0530') && formatDate(selectedHour, 'dd-MM-yyyy hh a', 'en-US', '+0530') === formatDate(createdMeterDate, 'dd-MM-yyyy hh a', 'en-US', '+0530')) {
        nextbutton = false;
        disableNextButton = true;
        prevbutton = false;
        disablePrevButton = true;
      } else if (formatDate(selectedHour, 'dd-MM-yyyy hh a', 'en-US', '+0530') === formatDate(new Date(), 'dd-MM-yyyy hh a', 'en-US', '+0530') && formatDate(selectedHour, 'dd-MM-yyyy hh a', 'en-US', '+0530') <= formatDate(createdMeterDate, 'dd-MM-yyyy hh a', 'en-US', '+0530')) {
        nextbutton = true;
        disableNextButton = false;
        prevbutton = false;
        disablePrevButton = true;
      } else if (formatDate(selectedHour, 'dd-MM-yyyy hh a', 'en-US', '+0530') === formatDate(new Date(), 'dd-MM-yyyy hh a', 'en-US', '+0530') && formatDate(selectedHour, 'dd-MM-yyyy hh a', 'en-US', '+0530') >= formatDate(createdMeterDate, 'dd-MM-yyyy hh a', 'en-US', '+0530')) {
        nextbutton = false;
        disableNextButton = true;
        prevbutton = true;
        disablePrevButton = false;
      } else if (formatDate(selectedHour, 'dd-MM-yyyy hh a', 'en-US', '+0530') !== formatDate(new Date(), 'dd-MM-yyyy hh a', 'en-US', '+0530') && formatDate(selectedHour, 'dd-MM-yyyy hh a', 'en-US', '+0530') >= formatDate(createdMeterDate, 'dd-MM-yyyy hh a', 'en-US', '+0530')) {
        nextbutton = true;
        disableNextButton = false;
        prevbutton = true;
        disablePrevButton = false;
      }
      cb(true, nextbutton, disableNextButton, prevbutton, disablePrevButton, selectedHour, ampm, hours);
    }
  }
  /* get the users based on selected location */
  getUserByLocation(userType, locationid, cb) {
    let resp;
    this.getUsersByLocationId(userType, locationid).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }

  getUpdatedData(obj, cb) {
    let resp;
    this.api.updatedata(obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp);
      } else {
        cb(false, resp.massage);
      }
    });
  }

  /* add the users for booking or cleaning based on userType */
  addUserByUserType(data1, cb) {
    let resp;
    this.AddUser(data1).subscribe(data1 => {
      resp = data1;
      if (resp.status === 1) {
        cb(true, resp);
      } else {
        cb(false, resp.message);
      }
    });
  }

  /* get the selected user details of workspace based on userId */
  getSelectedUserDetails(userId, cb) {
    let resp;
    this.getUserDetailsByUserId(userId).subscribe(data2 => {
      resp = data2;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }
  getPDFData(quary, type) {
    var mediaType = 'application/pdf';
    this.api.getpdffile(quary).subscribe((data: any) => {
      try {
        if (data.type === 'application/pdf') {
          let blob = new Blob([data], { type: mediaType });
          saveAs(blob, `${type}_${new Date().getTime()}.pdf`);
        } else {
          this.snackbar.open('No report found', 'Ok');
          setTimeout(() => {
            this.snackbar.dismiss();
          }, 10000);
        }
      } catch (err) {

      }
    });
  }

  /* get all the floors or rooms information */
  getAllFloorsRooms(type, locationId, floorid, cb) {
    let locs;
    this.getDetails(type, locationId, floorid, (res) => {
      locs = res;
      cb(true, locs);
    });
  }

  /* update the selected user workspace */
  updateuserworkspace(userid, uuid, data, cb) {
    let resp;
    this.updateUserWorkspace(userid, uuid, data).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }

  /* add the new workspace for the selected user */
  addUserWorkspaceDetails(userid, obj, cb) {
    let resp;
    this.addUserWorkspace(userid, obj).subscribe(data3 => {
      resp = data3;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }

  /* delete the workspace for the selected user*/
  deleteUserWorkSpace(userid, uuid, cb) {
    let resp;
    this.deleteUserWorkspace(userid, uuid).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.message);
      } else {
        cb(false, resp.message);
      }
    });
  }

  /* get all the desk for selected floor */
  getAllFloorDesks(floorid, cb) {
    let resp;
    this.getAllLocationDesk(floorid).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }

  /* get all the desks based on the selected room */
  getSelectedRoomDesk(roomid, cb) {
    let resp;
    this.getAllRoomDesk(roomid).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }

  /* get the location details by passing locationId notification/scenesettings */
  getSelectedLocationDetails(locid, cb) {
    let resp;
    this.getLocationDetails(locid).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }

  /* update the selected location details notification/scenesettings */
  UpdateSelectedLocationDetails(locid, data, cb) {
    let resp;
    this.updateLocationDetails(locid, data).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }

  /* get the selected room details */
  getSelectedRoomDetails(roomid, key, cb) {
    let resp;
    this.api.getRoomDevices(roomid, key).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }

  /* get the device internal details */
  getDeviceDetails(type, deviceid, obj, cb) {
    let resp;
    this.api.getStates(type, deviceid, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }

  /* set the height, min, max details of occ and sb devices */
  setDeviceHeightMaxMin(deviceid, obj, cb) {
    let resp;
    this.api.setDeviceEnergyandHeight(deviceid, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false);
      }
    });
  }

  /* get the floor internal details */
  getFloorDetails(floorid, cb) {
    let resp;
    this.api.getFloorRoomDetails(floorid).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }

  /* get the occ/smartbox details in floor level */
  getOccBoxFloorDetails(floorid, type, cb) {
    let resp;
    this.getFloorDevices(floorid, type).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }
  /* get the occ/smartbox details in location level */
  getOccBoxLocationDetails(locid, type, cb) {
    let resp;
    this.getLocationDevices(locid, type).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }
  getbookingHistory(obj, cb) {
    let resp;
    this.getbookingHistoryDetails(obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }

  getcleaningHistory(obj, cb) {
    let resp;
    this.getcleaningHistoryDetails(obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }

  addcordinates(obj, did, cb) {
    let resp;
    this.addcoordinatesDesk(obj, did).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false);
      }
    });
  }

  /* setState api for devices */

  setDevicesState(deviceid, obj, cb) {
    let resp;
    this.getHistoryDeviceDetails(deviceid, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false);
        if (resp.status === 0) {
          this.snackbar.open('You dont have access to control', 'Ok');
        }
      }
    });
  }

  /* setState for smartbox control */
  setSmartBoxDeviceState(deviceid, obj, type, cb) {
    let resp;
    this.setStateSmartBox(deviceid, obj, type).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false);
        if (resp.status === 0) {
          this.snackbar.open('You dont have access to control', 'Ok');
        }
      }
    });
  }

  /* get logged in user details */
  getLoginUserDetails() {
    let resp;
    this.api.details().subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        this.loginUserDetails = resp.data;
      } else {
        this.loginUserDetails = '';
      }
    });
  }

  getUserDetails(cb) {
    let resp;
    this.api.details().subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }

  /* energy minutes */
  getDeviceEnergyMinutes(deviceid, obj, cb) {
    let resp;
    this.api.getEnergyMinutes(deviceid, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.results);
      } else {
        cb(false);
      }
    });
  }
  getDeviceEnergyData(deviceid, type, obj, cb) {
    let resp;
    this.api.getEnergyData(deviceid, type, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false);
      }
    });
  }
  getDeviceEnergyHours(deviceid, obj, cb) {
    let resp;
    this.api.getEnergyHours(deviceid, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false);
      }
    });
  }
  getDeviceEnergyDays(deviceid, obj, cb) {
    let resp;
    this.api.getEnergyDays(deviceid, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.results);
      } else {
        cb(false);
      }
    });
  }
  getDeviceEnergyMonths(deviceid, obj, cb) {
    let resp;
    this.api.getEnergyMonths(deviceid, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.results);
      } else {
        cb(false);
      }
    });
  }

  /* occ state */
  getDeviceOccState(deviceid, obj, cb) {
    let resp;
    this.api.getOccState(deviceid, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.results);
      } else {
        cb(false);
      }
    });
  }
  getDeviceOccDays(deviceid, obj, cb) {
    let resp;
    this.api.getOccDays(deviceid, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.results);
      } else {
        cb(false);
      }
    });
  }
  getDeviceOccMonths(deviceid, obj, cb) {
    let resp;
    this.api.getOccMonths(deviceid, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.results);
      } else {
        cb(false);
      }
    });
  }

  /* get the devices based on deskid */
  getDeskDevices(deskid, cb) {
    let resp;
    this.getDevicesOfDesk(deskid).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false);
      }
    });
  }

  /* get rgb status for single/multiple sensors */
  getsensorrgbstatus(deviceid, type, obj, cb) {
    let resp;
    this.getrgbsensorstates(deviceid, type, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false);
      }
    });
  }

  /* get rgb status total time for devices */
  getTotalTimergbstatus(deviceid, type, obj, cb) {
    let resp;
    this.getrgbstatesTime(deviceid, type, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false);
      }
    });
  }

  areaName;
  sensorName;
  getRoomSensorNames(floorid, devid, cb) {
    this.getOccBoxFloorDetails(floorid, 'box', (status, result) => {
      if (status) {
        result.forEach(dev => {
          this.getFloorDetails(floorid, (status, res) => {
            if (status) {
              if (dev.device_id === devid) {
                this.sensorName = dev.name;
                res.forEach(rms => {
                  if (rms.is_default === false) {
                    if (rms.room_id === dev.room_id) {
                      this.areaName = rms.room_name;
                    }
                  }
                });
              }
            }
            cb(this.areaName, this.sensorName);
          });
        });
      }
    });
  }

  getheatmapenergydata(locid, type, obj, cb) {
    let resp;
    this.getheatmapdata(obj, locid, type).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.devices);
      } else {
        cb(false);
      }
    });
  }

  /* get the occupancy trends data */

  getoccupancydetails(levelid, type, endvalue, obj, cb) {
    let resp;
    this.getocctrendsdata(levelid, type, endvalue, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false);
      }
    });
  }
  /* get the setting Data */
  getsittingdetails(levelid, type, endvalue, obj, cb) {
    let resp;
    this.getsittingdata(levelid, type, endvalue, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false);
      }
    });
  }
  /* get the setting Data */
  getsittingsdetails(levelid, type, endvalue, obj, cb) {
    let resp;
    this.getsittingsdata(levelid, type, endvalue, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false);
      }
    });
  }
  /* get the superlocation Desks */
  getalldeskofsuperlocation(suplocid, cb) {
    let resp;
    this.getdeskofsuperlocation(suplocid).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp.message);
      }
    });
  }

  addaccesscontroluser(obj, cb) {
    let resp;
    this.addaccesscontrol(obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data || resp.message);
      } else {
        cb(false, resp);
      }
    });
  }
  gettheuserofaccesscontrol(suplocid, cb) {
    let resp;
    this.getaccesscontroluser(suplocid).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp);
      }
    });
  }

  deletetheuserofaccesscontrol(suplocid, userid, cb) {
    let resp;
    this.deleteaccesscontroluser(suplocid, userid).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp);
      }
    });
  }
  updatetheuserofaccesscontrol(roomid, obj, cb) {
    let resp;
    this.updateaccesscontroluser(roomid, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp);
      } else {
        cb(false, resp);
      }
    });
  }
  visible = true;
  callsessiondialog(message) {
    let dialogRef;
  }

  showSuccess(message, title) {
    this.toastr.info(message, title, { closeButton: true, progressBar: true });
  }
  showError(message, title) {
    this.toastr.error(message, title);
  }
  showInfo(message, title) {
    this.toastr.info(message, title);
  }
  showWarning(message, title) {
    this.toastr.warning(message, title);
  }

  getonlineofflinenotifications(type, id, obj, cb) {
    let resp;
    this.getnotifications(type, id, obj).subscribe(data => {
      resp = data;
      if (resp.status === 1) {
        cb(true, resp.data);
      } else {
        cb(false, resp);
      }
    });
  }
}
